import React, { useEffect } from "react";
import { UseModalFormReturnType } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { Edit } from "@refinedev/mui";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { HttpError } from "@refinedev/core/dist/interfaces";
import { Autocomplete, Chip, Typography } from "@mui/material";

interface EditProductGroupProps extends UseModalFormReturnType<HttpError> {
    menu?: string; // Assuming menu is passed as a prop
    allProducts: ProductType[]; 
    productGroupData: ProductGroupType,
    close: () => void;
    visible: boolean;
}

interface ProductType {
    _id: string; // Substitute 'string' with correct type
    name: string;
  }
interface ProductGroupType {
    _id: string; // Substitute 'string' with correct type
    name: string;
    description: string;
    products: ProductType[];
  }

export const EditProductGroup: React.FC<EditProductGroupProps> = ({
    visible,
    close,
    allProducts, 
    menu,
    saveButtonProps,
    refineCore: { onFinish },
    register, 
    handleSubmit, 
    control, 
    productGroupData,
    setValue, 
    formState: { errors },
    reset
}) => {

    useEffect(() => {
        if (menu) {
            setValue('menu', menu);
        }
    }, [menu, setValue]);

    useEffect(() => {
        if (productGroupData && visible) {
          // Using reset to set all form values
          reset({
            ...productGroupData
          });
        }
      }, [productGroupData, visible, reset]);


    return (
        <Drawer
            sx={{ zIndex: "1301" }}
            PaperProps={{ sx: { width: { sm: "100%", md: 500 } } }}
            open={visible}
            onClose={close}
            anchor="right"
        >
             <Edit
                saveButtonProps={saveButtonProps}
                headerProps={{
                    title: (<Typography>Créer un groupe de produits</Typography>),
                    avatar: (
                        <IconButton
                            onClick={() => close()}
                            sx={{
                                width: "30px",
                                height: "30px",
                                mb: "5px",
                            }}
                        >
                            <CloseOutlined />
                        </IconButton>
                    ),
                    action: null,
                }}
                wrapperProps={{ sx: { overflowY: "scroll", height: "100vh" } }}
            >

                <form onSubmit={handleSubmit(onFinish)}>
                    <Box
                        paddingX="50px"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            paddingX: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Name"
                                {...register("name", { required: "Name is required" })}
                                error={!!errors.name}
                                // helperText={errors.name?.message}
                                variant="outlined"
                                defaultValue={productGroupData?.name}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Description"
                                {...register("description")}
                                multiline
                                rows={4}
                                variant="outlined"
                                defaultValue={productGroupData?.description}
                            />
                        </FormControl>
                        <input
                            {...register('menu')}
                            type="hidden"
                            value={menu}
                        />

                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="products"
                                control={control}
                                render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    multiple
                                    id="products-select"
                                    options={allProducts}
                                    getOptionLabel={(option) => option?.name}
                                    defaultValue={[]}
                                    renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip label={option?.name} {...getTagProps({ index })} />
                                    ))
                                    }
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Select Products"
                                        placeholder="Start typing to search products"
                                    />
                                    )}
                                />
                                )}
                            />
                            </FormControl>
                    </Box>
                </form>
            </Edit>
        </Drawer>
    );
};