import React, { useEffect, useState } from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    DeleteButton,
    List,
    TagField,
    DateField,
} from "@refinedev/mui";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import {
    IResourceComponentsProps,
    useTranslate,
    useMany,
    useNavigation,
    useModal,
    useShow,
    useNotification,
    useApiUrl
} from "@refinedev/core";
import { CustomTooltip } from "components/customTooltip";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditOutlined from "@mui/icons-material/EditOutlined";
import { Paper, Stack, Typography } from "@mui/material";
import { socket } from "App";
import { format } from "date-fns";
import newOrderSound from '../../sound/new_order_notif.mp3';
import MenuToggleSwitch from "./menuToggleSwitch"; // Adjust the import path as necessary


const orderCreatedSound = new Audio(newOrderSound);

export const MenuList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { edit } = useNavigation();
    const apiUrl = useApiUrl();

    const { show, visible, close } = useModal();

    const { queryResult, setShowId } = useShow();
    const { data: showQueryResult } = queryResult;
    const record = showQueryResult?.data;
    const { dataGridProps } = useDataGrid({
        initialPageSize: 10,
    });

    const notif = useNotification();
    const [isActive, setIsActive] = useState(true); 
    useEffect(() => {
      socket.on('groupOrderCreated', (newOrder) => {
          if(notif.open) {
              notif.open({
                  message: 'New order received',
                  type: 'success',
              });
          }
          orderCreatedSound.play();
      });
  
      socket.on('groupOrderUpdated', (updatedOrder) => {
        
          if(notif.open) {
  
              notif.open({
                  message: 'Order updated',
                  description: `Order ${updatedOrder.groupOrderNumber} has been updated.`,
                  type: 'success',
              });
          }
         
      });
  
      socket.on(`groupOrderDeleted`, (deletedOrder) => {
        
          if(notif.open) {
  
              notif.open({
                  message: 'Order deleted',
                  description: `Order ${deletedOrder.groupOrderNumber} has been deleted.`,
                  type: 'progress',
                });
          }
      });
  
      return () => {
        socket.off('groupOrderCreated');
        socket.off('groupOrderUpdated');
        socket.off('groupOrderDeleted');
      };
    }, []);
  

    // const { data: restaurantBrandData, isLoading: restaurantBrandIsLoading } =
    //     useMany({
    //         resource: "restaurantBrands",
    //         ids:
    //             dataGridProps?.rows?.map(
    //                 (item: any) => item?.restaurantBrand,
    //             ) ?? [],
    //         queryOptions: {
    //             enabled: !!dataGridProps?.rows,
    //         },
    //     });

    const { data: categoriesData, isLoading: categoriesIsLoading } = useMany({
        resource: "categories",
        ids: [].concat(
            ...(dataGridProps?.rows?.map((item: any) => item?.categories) ??
                []),
        ),
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });


    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "restaurantBrand",
                flex: 1,
                headerName: translate("menus.fields.restaurantBrand"),
                minWidth: 300,
                renderCell: function render({ value }) {
                    return value.name
                    // return value.restaurantBrand?.name
                },
            },
            {
                field: "name",
                flex: 1,
                headerName: translate("menus.fields.name"),
                minWidth: 200,
            },
            {
                field: "description",
                flex: 1,
                headerName: translate("menus.fields.description"),
                minWidth: 200,
            },
            {
                field: "shippingPrice",
                flex: 1,
                headerName: translate("menus.fields.shippingPrice"),
                type: "number",
                minWidth: 200,
            },
            {
                field: "coverImage",
                flex: 1,
                headerName: translate("menus.fields.coverImage"),
                minWidth: 100,
                renderCell: function render({ value }) {
                    return (
                        <img
                            src={apiUrl + "/" + value}
                            style={{ height: "50px", maxWidth: "100px" }}
                        />
                    );
                },
            },
            {
                field: "categories",
                flex: 1,
                headerName: translate("menus.fields.categories"),
                minWidth: 300,
                renderCell: function render({ value }) {
                    console.log(value)

                    return categoriesIsLoading ? (
                        <>Loading...</>
                    ) : (
                        <>
                            {value?.map((item: any, index: number) => (
                                <TagField
                                    key={index}
                                    value={
                                        categoriesData?.data?.find(
                                            (resourceItems) =>
                                                resourceItems._id === item,
                                        )?.name
                                    }
                                />
                            ))}
                        </>
                    );
                },
            },
            {
                field: "createdAt",
                flex: 1,
                headerName: translate("orders.fields.createdAt"),
                minWidth: 250,
                renderCell: function render({ value }) {
                    // Use `format` from date-fns to format your date-time value
                    const formattedDate = format(new Date(value), "PPpp");
                    return <Typography sx={{ fontSize: "14px" }}>{formattedDate}</Typography>;
                },
            },
            {
                field: "updatedAt",
                flex: 1,
                headerName: translate("orders.fields.updatedAt"),
                minWidth: 250,
                renderCell: function render({ value }) {
                    const formattedDate = format(new Date(value), "PPpp");
                    return <Typography sx={{ fontSize: "14px" }}>{formattedDate}</Typography>;
                },
            },
            {
                field: "productGroups",
                flex: 1,
                headerName: translate("menus.fields.groups"),
                headerAlign: "center",
                align: "center",
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <CustomTooltip
                            arrow
                            placement="top"
                            title={
                                <Stack sx={{ padding: "2px" }}>
                                    {row.productGroups.map((group: any) => (
                                        <li key={group._id}>{group.name}</li>
                                    ))}
                                </Stack>
                            }
                        >
                            <Typography sx={{ fontSize: "14px" }}>
                                {/* {translate("orders.fields.itemsAmount", {
                                    amount: row.products.length,
                                })} */}
                                {row.productGroups.length}
                            </Typography>
                        </CustomTooltip>
                    );
                },
                minWidth: 100,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            {/* <GridActionsCellItem
                                key={1}
                                label={"menu"}
                                icon={<EditOutlined />}
                                showInMenu
                                onClick={() => edit("menus", row._id)}
                            />
                            /*}
                            {/*
                            <GridActionsCellItem
                                onClick={() => {
                                    show();
                                    setShowId(row._id);
                                }}
                                key={2}
                                label={"products"}
                                icon={<EditOutlined />}
                                showInMenu
                            /> */}
                            <ShowButton hideText recordItemId={row._id} />
                            <EditButton hideText recordItemId={row._id} />
                            <DeleteButton hideText recordItemId={row._id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [translate, categoriesData?.data],
    );
  
    return (
        <Paper>
            <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ margin: 2 }}>
                <MenuToggleSwitch initialStatus={isActive}/>
            </Stack>
            <List
                canCreate
                wrapperProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}
            >
                <DataGrid 
                    {...dataGridProps} 
                    columns={columns} 
                    autoHeight 
                    density="comfortable"
                    pageSizeOptions={[10, 20, 50, 100]}
                    getRowId={row => row._id} 
                />
            </List>
            {/* {record && (
                <StoreProducts
                    record={record}
                    close={close}
                    visible={visible}
                />
            )} */}
        </Paper>
    );
};
