  /* eslint-disable  @typescript-eslint/no-explicit-any */
  import { AuthBindings } from "@refinedev/core";
// import { baseUrl } from "App";
  import {jwtDecode} from "jwt-decode";

  export const TOKEN_KEY = "refine-auth";
  export interface MyTokenPayload {
    user: {
      id: string;
      first_name: string;
      last_name: string;
      avatar: string;
      role: string;
    };
    iat: number;
    exp: number;
    // any other properties that are in your token payload
  }
  export const authProvider: AuthBindings = {
  
    login: async ({ email, password }) => {
      const baseUrl = "https://api.backend.saheat.tn/api/v1/admin";
      const response = await fetch(`${baseUrl}/authentication/login`, {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          body: JSON.stringify({ email, password }),
      });

      if (response.status < 200 || response.status >= 300) {

        return {
          success: false,
          error: {
            name: "Login Error",
            message: "Error while login",
          },
        };
      }

      const token = response.headers.get('Authorization')?.split(' ')[1];
      
      if (token) {
        const decoded = jwtDecode<MyTokenPayload>(token);
        console.log(decoded.user);
          if (decoded.user?.role !== "admin" && decoded.user?.role !== "dispatcher") 
            return {
              success: false,
              error: {
                name: "LoginError",
                message: "Permission denied this user isn't an admin or dispatcher",
              },
            };
          localStorage.setItem(TOKEN_KEY, token);
          return {
            success: true,
            redirectTo: "/",
          };
      }

      return {
        success: false,
        error: {
          name: "LoginError",
          message: "Error obtaining token",
        },
      };
  },
    logout: async () => {
      localStorage.removeItem(TOKEN_KEY);
      return {
        success: true,
        redirectTo: "/login",
      };
    },
    check: async () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        return {
          authenticated: true,
        };
      }

      return {
        authenticated: false,
        redirectTo: "/login",
      };
    },
    getPermissions: async () => null,
    getIdentity: async () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        // Decode the token to get the user data
        try {
          // Assuming the user data is contained in the token's payload
          const decoded = jwtDecode<MyTokenPayload>(token);
          // You can then structure this information as it makes sense for your system
          // For example:
          return {
            id:  decoded.user.id, // Make sure the property names match those in your token
            name: decoded.user.first_name + " " + decoded.user.last_name,
            role: decoded.user.role,
            avatar: "https://i.pravatar.cc/300", // Assuming the token includes a link to the avatar
          };
        } catch (error) {
          console.error("Token decoding failed", error);
          // Handle the error (for example, redirect to login)
          return null;
        }
      }
      return null;
    },
    onError: async (error) => {
      console.error(error);
      return { error };
    },
  };
