import { SVGProps } from "react";

export const FineFoodsIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="180px" height="60px" viewBox="0 0 1173 420"
 preserveAspectRatio="xMidYMid meet"  style={{background: "#C2185B", fill: "white"}}>

<g transform="translate(0.000000,420.000000) scale(0.100000,-0.100000)"
stroke="none">
<path d="M0 2100 l0 -2100 5865 0 5865 0 0 2100 0 2100 -2705 0 c-1797 0
-2705 -3 -2705 -10 0 -5 7 -10 16 -10 9 0 24 -10 35 -22 17 -22 18 -45 19
-360 0 -185 -3 -343 -6 -352 -5 -14 -40 -16 -285 -16 l-279 0 0 -24 c0 -59 25
-56 -426 -56 l-414 0 0 285 0 285 130 0 130 0 0 -145 0 -145 155 0 155 0 2
233 3 232 130 0 130 0 3 -182 2 -183 155 0 155 0 0 179 c0 134 -3 181 -12 185
-10 5 -10 7 0 12 6 3 12 18 12 34 0 20 6 31 20 35 11 3 20 11 20 16 0 5 -1184
9 -3085 9 l-3085 0 0 -2100z m4300 1496 c0 -30 -4 -57 -9 -60 -5 -3 -11 -32
-14 -63 -7 -65 -19 -157 -42 -318 -24 -163 -34 -258 -41 -372 -4 -57 -10 -103
-15 -103 -5 0 -9 -52 -9 -115 0 -70 -4 -115 -10 -115 -6 0 -10 -58 -10 -154 0
-93 -4 -157 -10 -161 -5 -3 -10 -24 -10 -46 0 -39 0 -39 40 -39 22 0 40 5 40
10 0 6 42 10 105 10 63 0 105 4 105 10 0 6 43 10 110 10 67 0 110 4 110 10 0
6 42 10 105 10 58 0 105 4 105 9 0 5 44 12 98 15 164 10 550 49 622 63 28 6
12 10 -80 20 -63 7 -161 16 -217 19 -57 4 -103 10 -103 15 0 5 -43 9 -95 9
-57 0 -95 4 -95 10 0 6 -41 10 -104 10 -59 0 -108 4 -111 10 -3 6 -50 10 -105
10 -54 0 -101 4 -104 9 -3 4 -47 11 -98 14 -109 8 -194 16 -226 23 -23 5 -23
8 -18 62 4 31 11 104 15 162 25 293 57 636 67 702 6 45 7 46 38 42 31 -4 165
-18 341 -35 50 -5 138 -12 198 -15 59 -3 107 -10 107 -15 0 -5 47 -9 105 -9
63 0 105 -4 105 -10 0 -6 41 -10 104 -10 59 0 108 -4 111 -10 3 -6 49 -10 104
-10 53 0 101 -4 106 -9 6 -5 51 -12 100 -15 105 -6 232 -18 435 -41 80 -9 188
-19 241 -22 l97 -5 5 38 c4 22 9 57 12 79 24 165 38 225 54 229 9 3 52 10 96
16 44 6 102 15 128 21 27 5 73 9 103 9 30 0 54 5 54 10 0 6 32 10 75 10 43 0
75 4 75 10 0 6 32 10 75 10 43 0 75 4 75 10 0 6 32 10 75 10 41 0 75 4 75 9 0
5 30 12 68 15 74 5 155 16 307 41 138 23 215 29 225 19 11 -12 -17 -476 -46
-755 l-6 -56 -92 -8 c-50 -3 -129 -11 -176 -16 -47 -6 -124 -12 -172 -15 -49
-3 -88 -10 -88 -15 0 -5 -34 -9 -75 -9 -43 0 -75 -4 -75 -10 0 -5 -21 -10 -47
-10 l-48 0 -18 -63 c-10 -34 -22 -76 -27 -93 -6 -17 -10 -47 -10 -67 0 -20 -4
-37 -10 -37 -5 0 -10 -22 -10 -50 0 -27 -4 -50 -10 -50 -5 0 -10 -25 -10 -56
0 -50 2 -55 18 -49 9 4 37 11 62 15 58 10 226 48 266 60 17 6 44 10 62 10 17
0 34 5 37 10 3 6 28 10 56 10 27 0 49 5 49 10 0 6 20 10 45 10 25 0 45 5 45
10 0 6 9 10 20 10 18 0 20 -7 20 -75 0 -43 -4 -75 -10 -75 -6 0 -10 -60 -10
-160 0 -100 -4 -160 -10 -160 -6 0 -10 -32 -10 -75 l0 -75 -40 0 c-22 0 -40
-4 -40 -10 0 -5 -25 -10 -55 -10 -30 0 -55 -4 -55 -10 0 -5 -25 -10 -55 -10
-30 0 -55 -4 -55 -9 0 -5 -24 -11 -52 -14 -29 -4 -84 -13 -123 -22 -80 -18
-163 -34 -217 -42 -21 -3 -38 -9 -38 -14 0 -5 -7 -9 -16 -9 -29 0 -44 -27 -44
-79 0 -28 -4 -51 -10 -51 -6 0 -10 -26 -10 -59 0 -33 -4 -63 -10 -66 -5 -3
-10 -20 -10 -37 l0 -30 58 5 c52 4 232 32 373 57 30 6 74 10 97 10 22 0 44 5
47 10 3 6 37 10 76 10 39 0 69 4 69 10 0 6 18 10 39 10 41 0 62 -23 41 -44 -5
-5 -10 -42 -9 -80 0 -59 2 -67 11 -46 5 14 14 33 19 42 5 10 9 29 9 43 0 14 5
25 10 25 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 14 10 30 0 17 5 30 10 30 6
0 10 9 10 20 0 11 4 28 9 38 5 9 22 58 37 107 16 50 32 92 37 93 4 2 7 17 7
33 0 16 5 29 10 29 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 16 10 35 0 19 5
35 10 35 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 13 10 28 0 15 4 32 8 38 5 5
14 29 22 54 7 25 22 70 32 100 11 30 22 67 25 83 3 15 9 27 14 27 5 0 9 18 9
40 0 22 5 40 10 40 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 16 10 35 0 19 4
35 9 35 5 0 11 15 14 33 7 47 47 180 58 191 5 6 9 22 9 37 0 15 5 31 10 34 6
3 10 19 10 36 0 16 5 29 10 29 6 0 10 18 10 40 0 22 5 40 10 40 6 0 10 16 10
35 0 19 5 35 10 35 6 0 10 18 10 40 0 22 4 40 9 40 5 0 11 17 14 38 4 34 46
185 62 223 7 19 29 25 295 70 147 26 179 31 225 36 28 2 54 9 60 14 5 5 35 9
67 9 32 0 58 4 58 10 0 6 28 10 65 10 36 0 65 4 65 9 0 5 26 12 58 15 74 7
152 19 287 45 155 29 145 32 145 -39 0 -33 -4 -60 -10 -60 -6 0 -10 -30 -10
-70 0 -40 -4 -70 -10 -70 -6 0 -10 -32 -10 -75 0 -41 -4 -75 -9 -75 -5 0 -11
-33 -14 -72 -11 -134 -25 -256 -31 -267 -8 -13 -26 -206 -33 -348 -2 -57 -8
-103 -14 -103 -5 0 -9 -67 -9 -160 0 -100 -4 -160 -10 -160 -6 0 -10 -75 -10
-205 0 -130 -4 -205 -10 -205 -7 0 -10 -208 -10 -605 0 -531 -2 -605 -15 -605
-8 0 -15 -4 -15 -10 0 -5 -22 -10 -50 -10 -27 0 -50 -4 -50 -10 0 -5 -18 -10
-39 -10 -22 0 -42 -4 -45 -9 -3 -5 -20 -11 -38 -14 -18 -3 -64 -13 -103 -22
-38 -9 -88 -21 -110 -26 -89 -22 -132 -31 -172 -36 -24 -3 -43 -9 -43 -14 0
-5 -20 -9 -45 -9 -25 0 -45 -4 -45 -10 0 -5 -20 -10 -45 -10 -25 0 -45 -4 -45
-10 0 -5 -18 -10 -40 -10 -39 0 -40 1 -40 35 0 19 5 35 10 35 6 0 10 36 10 88
0 48 4 92 9 98 5 5 12 47 15 94 3 47 8 111 12 143 l6 57 -31 0 c-17 0 -31 -4
-31 -10 0 -5 -22 -10 -50 -10 -27 0 -50 -4 -50 -10 0 -5 -24 -10 -53 -10 l-53
0 -11 -42 c-6 -24 -21 -79 -33 -123 -13 -44 -25 -97 -27 -117 -3 -21 -9 -38
-14 -38 -5 0 -9 -13 -9 -29 0 -33 -2 -35 -105 -50 -38 -6 -86 -13 -105 -17
-19 -3 -65 -8 -102 -11 -38 -2 -68 -9 -68 -14 0 -5 -29 -9 -65 -9 -37 0 -65
-4 -65 -10 0 -6 -32 -10 -75 -10 -43 0 -75 -4 -75 -10 0 -6 -32 -10 -75 -10
-43 0 -75 -4 -75 -10 0 -5 -25 -10 -55 -10 -65 0 -67 6 -30 82 14 28 25 60 25
70 0 10 5 18 10 18 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 14 10 30 0 17 5 30
10 30 6 0 10 12 10 26 0 21 -4 25 -17 20 -10 -4 -63 -14 -118 -22 -55 -8 -127
-20 -160 -25 -33 -6 -82 -13 -110 -16 -27 -2 -54 -9 -59 -14 -6 -5 -32 -9 -58
-9 -26 0 -48 -4 -48 -10 0 -5 -25 -10 -55 -10 -30 0 -55 -4 -55 -10 0 -5 -25
-10 -55 -10 -30 0 -55 -4 -55 -10 0 -5 -18 -10 -40 -10 -22 0 -40 -4 -40 -10
0 -5 -13 -10 -28 -10 -16 0 -64 -9 -108 -21 -118 -31 -155 -40 -197 -46 -20
-3 -40 -9 -43 -14 -3 -5 -21 -9 -40 -9 -19 0 -34 -4 -34 -10 0 -5 -21 -10 -46
-10 -43 0 -46 1 -39 23 4 12 11 48 16 80 5 33 11 63 14 68 7 10 25 125 32 202
2 31 9 57 14 57 5 0 9 29 9 65 0 37 4 65 10 65 6 0 10 27 10 60 0 33 4 60 10
60 6 0 10 28 10 65 0 37 4 65 10 65 6 0 10 29 10 66 0 59 -2 65 -17 59 -10 -4
-63 -11 -118 -16 -55 -4 -163 -15 -240 -24 -77 -9 -185 -21 -240 -26 -55 -5
-143 -13 -195 -19 -52 -6 -134 -13 -182 -16 -74 -5 -88 -8 -88 -23 0 -9 9 -23
20 -31 11 -8 20 -22 20 -32 0 -10 5 -18 10 -18 6 0 10 -7 10 -15 0 -8 5 -15
10 -15 6 0 10 -6 10 -12 0 -7 11 -26 25 -43 37 -44 39 -48 54 -77 8 -15 22
-37 33 -47 10 -11 18 -25 18 -32 0 -6 8 -19 18 -28 27 -24 34 -49 16 -63 -8
-7 -26 -23 -39 -35 -13 -13 -29 -23 -35 -23 -5 0 -10 -4 -10 -10 0 -5 -7 -10
-15 -10 -8 0 -15 -4 -15 -10 0 -5 -5 -10 -10 -10 -11 0 -18 -5 -69 -51 -18
-15 -41 -31 -52 -34 -10 -4 -19 -11 -19 -16 0 -5 -6 -9 -13 -9 -7 0 -19 -9
-27 -20 -8 -11 -20 -20 -27 -20 -7 0 -28 -14 -45 -31 -18 -17 -45 -38 -60 -47
-16 -9 -28 -20 -28 -24 0 -4 -7 -8 -16 -8 -9 0 -29 -13 -44 -30 -15 -16 -33
-30 -40 -30 -6 0 -17 -6 -24 -12 -7 -7 -25 -22 -41 -33 -15 -11 -38 -30 -51
-42 -13 -13 -28 -23 -34 -23 -5 0 -10 -4 -10 -10 0 -23 -27 -8 -44 25 -11 19
-23 35 -28 35 -4 0 -8 9 -8 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 16 0 9 -8 24
-18 33 -10 9 -35 44 -57 78 -22 35 -43 63 -47 63 -5 0 -8 9 -8 20 0 11 -4 20
-10 20 -5 0 -10 7 -10 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8 -4 15 -10
15 -5 0 -10 7 -10 15 0 8 -4 15 -9 15 -5 0 -14 14 -21 30 -7 17 -15 30 -19 30
-4 0 -15 16 -25 35 -11 19 -23 35 -28 35 -4 0 -8 8 -8 18 0 10 -9 24 -20 32
-11 8 -20 22 -20 31 0 10 -3 19 -7 21 -5 2 -15 16 -24 31 -9 16 -20 27 -25 25
-5 -1 -9 -92 -9 -201 0 -151 -3 -197 -12 -197 -7 0 -13 -4 -13 -10 0 -5 -22
-10 -50 -10 -27 0 -50 -4 -50 -10 0 -5 -18 -10 -39 -10 -22 0 -42 -4 -45 -9
-3 -4 -22 -11 -43 -14 -34 -6 -91 -19 -208 -48 -79 -20 -131 -31 -172 -36 -24
-3 -43 -9 -43 -14 0 -5 -20 -9 -45 -9 -25 0 -45 -4 -45 -10 0 -5 -20 -10 -45
-10 -25 0 -45 -4 -45 -10 0 -5 -18 -10 -41 -10 l-41 0 6 43 c4 23 9 81 12 130
3 48 10 87 15 87 5 0 9 43 9 95 0 57 4 95 10 95 6 0 10 23 10 50 0 49 -1 50
-30 50 -16 0 -30 -4 -30 -10 0 -5 -25 -10 -55 -10 -30 0 -55 -4 -55 -10 0 -5
-22 -10 -49 -10 l-48 0 -17 -58 c-9 -32 -16 -66 -16 -75 0 -9 -4 -17 -10 -17
-5 0 -10 -18 -10 -40 0 -22 -4 -40 -10 -40 -5 0 -10 -20 -10 -45 0 -25 -4 -45
-10 -45 -5 0 -10 -13 -10 -30 0 -32 5 -30 -105 -49 -97 -17 -136 -22 -205 -28
-36 -2 -69 -9 -74 -14 -6 -5 -36 -9 -67 -9 -32 0 -61 -4 -64 -10 -3 -5 -37
-10 -76 -10 -39 0 -69 -4 -69 -10 0 -6 -32 -10 -75 -10 -43 0 -75 -4 -75 -10
0 -5 -25 -10 -55 -10 -48 0 -55 2 -55 20 0 11 4 28 9 38 5 9 13 28 18 42 16
40 65 154 74 172 5 10 9 29 9 43 0 14 5 25 10 25 6 0 10 9 10 20 0 11 5 20 10
20 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 8 10 17 0 18 22 85 30 93 8 8 32
73 37 103 3 15 9 27 14 27 5 0 9 11 9 25 0 14 5 25 10 25 6 0 10 14 10 30 0
17 5 30 10 30 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 11 10 24 0 14 5 28 10
31 6 3 10 17 10 31 0 13 5 24 10 24 6 0 10 15 9 33 0 30 -3 27 -32 -33 -17
-36 -35 -66 -39 -68 -4 -2 -8 -11 -8 -21 0 -9 -9 -23 -20 -31 -11 -8 -20 -22
-20 -32 0 -10 -4 -18 -10 -18 -5 0 -10 -5 -10 -10 0 -11 -10 -25 -47 -65 -13
-13 -23 -29 -23 -35 0 -6 -10 -21 -22 -34 -13 -13 -36 -40 -53 -61 -52 -64
-237 -245 -251 -245 -3 0 -15 -10 -28 -22 -13 -13 -36 -32 -51 -43 -16 -11
-34 -26 -41 -32 -7 -7 -17 -13 -23 -13 -6 0 -11 -4 -11 -10 0 -5 -7 -10 -15
-10 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -16 -10 -9 0 -24 -8 -33 -17 -18 -20 -41
-35 -88 -60 -18 -9 -33 -20 -33 -25 0 -4 -9 -8 -20 -8 -11 0 -20 -4 -20 -10 0
-5 -9 -10 -20 -10 -11 0 -20 -4 -20 -10 0 -5 -11 -10 -25 -10 -14 0 -25 -4
-25 -10 0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -10 0 -5 -13 -10 -29 -10 -17 0
-33 -4 -36 -10 -3 -5 -16 -10 -28 -10 -12 0 -40 -7 -62 -15 -27 -9 -81 -14
-165 -14 -84 0 -138 5 -165 14 -22 8 -50 15 -62 15 -12 0 -25 5 -28 10 -3 6
-19 10 -36 10 -16 0 -29 5 -29 10 0 6 -9 10 -20 10 -11 0 -20 5 -20 10 0 6 -9
10 -19 10 -11 0 -23 4 -26 10 -3 5 -15 10 -26 10 -14 0 -19 7 -19 25 0 14 5
25 10 25 6 0 10 11 10 24 0 14 5 28 10 31 6 3 10 15 10 26 0 10 5 19 10 19 6
0 10 7 10 14 0 8 11 36 25 62 14 26 25 53 25 60 0 8 5 14 10 14 6 0 10 9 10
20 0 11 5 20 10 20 6 0 10 11 10 25 0 14 5 25 10 25 6 0 10 6 10 14 0 20 90
210 101 214 5 2 9 15 9 28 0 13 5 24 10 24 6 0 10 9 10 20 0 11 5 20 10 20 6
0 10 12 10 26 0 26 20 54 39 54 6 0 11 -4 11 -10 0 -5 9 -10 20 -10 11 0 20
-4 20 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 9 -10 19 -10 11 0 22 -4
25 -9 19 -30 316 -30 316 0 0 5 14 9 30 9 17 0 30 5 30 10 0 6 11 10 25 10 14
0 25 5 25 10 0 6 14 10 30 10 17 0 30 3 30 8 0 4 15 14 32 21 76 34 177 115
178 143 0 9 5 20 10 23 6 3 10 21 10 38 0 36 -38 87 -64 87 -9 0 -16 5 -16 10
0 6 -45 10 -115 10 -63 0 -115 -4 -115 -9 0 -5 -26 -11 -58 -14 -31 -3 -77
-10 -102 -15 -244 -53 -258 -55 -395 -56 -113 -1 -149 3 -188 17 -26 10 -47
22 -47 25 0 4 -15 14 -32 21 -40 18 -78 55 -78 76 0 8 -4 15 -10 15 -5 0 -10
7 -10 15 0 8 -4 15 -10 15 -5 0 -10 14 -10 30 0 17 -5 30 -11 30 -7 0 -9 35
-5 108 6 138 16 212 27 212 5 0 9 16 9 35 0 19 5 35 10 35 6 0 10 14 10 30 0
17 5 30 10 30 6 0 10 7 10 15 0 21 72 175 82 175 4 0 8 9 8 20 0 11 5 20 10
20 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 7 10 15 0 8 5 15 10 15 6 0 10 8 10
18 0 10 9 24 20 32 11 8 20 22 20 32 0 10 4 18 8 18 4 0 16 15 27 33 10 17 27
39 37 48 10 9 18 23 18 30 0 8 9 21 20 29 11 8 20 20 20 26 0 7 3 14 8 16 4 2
23 23 42 48 50 66 53 69 77 95 13 13 23 27 23 30 0 3 19 26 43 50 23 25 61 65
84 90 24 24 43 47 43 50 0 9 69 75 78 75 5 0 15 11 22 25 7 13 21 27 32 31 10
3 18 11 18 17 0 7 12 21 27 32 14 11 32 26 39 33 7 6 17 12 23 12 6 0 11 5 11
10 0 6 4 10 10 10 5 0 29 18 52 40 22 23 54 48 70 58 15 9 28 20 28 24 0 4 7
8 16 8 9 0 24 8 33 18 20 22 97 72 111 72 5 0 10 5 10 10 0 6 9 10 20 10 11 0
20 5 20 10 0 6 11 10 25 10 14 0 25 5 25 10 0 6 14 10 30 10 17 0 30 5 30 10
0 6 13 10 29 10 16 0 31 5 33 11 5 16 251 16 256 1 2 -7 16 -12 31 -12 15 0
33 -9 41 -20 8 -11 18 -20 23 -20 12 0 57 -47 57 -60 0 -5 5 -10 10 -10 6 0
10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10
-11 10 -25 0 -14 4 -25 9 -25 22 0 42 -257 36 -465 -6 -195 -14 -235 -47 -235
-10 0 -18 -4 -18 -10 0 -5 -13 -10 -30 -10 -16 0 -30 -4 -30 -10 0 -5 -11 -10
-25 -10 -14 0 -25 -3 -25 -8 0 -4 -15 -12 -32 -18 -128 -45 -193 -70 -196 -76
-2 -5 -15 -8 -28 -8 -13 0 -24 -4 -24 -10 0 -5 -13 -10 -30 -10 -16 0 -30 -4
-30 -10 0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -10 0 -5 -13 -10 -28 -10 -15 0
-32 -4 -38 -9 -5 -5 -20 -11 -34 -13 -24 -3 -25 0 -28 50 -2 28 -7 52 -13 52
-5 0 -9 9 -9 20 0 12 -11 32 -25 45 -61 62 -212 9 -336 -117 -32 -33 -67 -73
-76 -89 -10 -16 -21 -29 -25 -29 -4 0 -8 -13 -8 -30 0 -16 -4 -30 -10 -30 -6
0 -10 -30 -10 -71 0 -75 12 -99 49 -99 12 0 21 -4 21 -10 0 -13 197 -13 205 0
3 6 28 10 56 10 27 0 49 5 49 10 0 6 28 10 65 10 37 0 65 4 65 10 0 6 63 10
170 10 107 0 170 -4 170 -10 0 -5 20 -10 45 -10 25 0 45 -4 45 -10 0 -5 9 -10
20 -10 11 0 20 -4 20 -10 0 -5 7 -10 15 -10 19 0 95 -77 95 -96 0 -8 5 -14 10
-14 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -18 10 -40 0 -22 5 -40 10
-40 6 0 10 -50 10 -130 0 -80 -4 -130 -10 -130 -5 0 -10 -16 -10 -35 0 -19 5
-35 10 -35 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 16 10 35 0 19 5 35 10 35
6 0 10 9 10 20 0 11 4 28 9 38 5 9 21 55 36 102 15 47 31 89 36 95 5 5 9 22 9
37 0 15 5 28 10 28 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 16 10 35 0 19 5
35 10 35 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 7 10 16 0 9 16 63 35 120 19
57 35 112 35 124 0 11 5 20 10 20 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 16
10 35 0 19 5 35 10 35 6 0 10 18 10 40 0 22 5 40 10 40 6 0 10 13 10 29 0 17
5 33 10 36 6 3 10 17 10 31 0 13 4 32 9 42 16 30 61 179 61 200 0 12 5 24 10
27 6 3 10 19 10 36 0 16 5 29 10 29 6 0 10 18 10 40 0 22 5 40 10 40 6 0 10
20 10 45 0 25 5 45 10 45 6 0 10 13 10 29 0 17 5 33 10 36 6 3 10 17 10 29 0
13 7 39 15 58 11 27 22 37 47 42 70 15 196 39 253 48 33 5 85 14 115 19 30 6
80 13 110 15 30 3 59 10 64 15 6 5 36 9 68 9 32 0 58 4 58 10 0 6 28 10 65 10
36 0 65 4 65 9 0 5 26 12 58 15 88 8 352 53 361 61 2 3 19 5 38 5 l33 0 0 -54z
m7270 -120 c0 -59 -4 -108 -10 -111 -6 -4 -10 -95 -10 -239 0 -128 -3 -261 -6
-295 l-7 -61 -83 0 c-49 0 -84 -4 -84 -10 0 -6 -55 -10 -145 -10 -90 0 -145
-4 -145 -10 0 -5 -22 -10 -49 -10 -72 0 -81 -6 -81 -51 0 -21 -4 -39 -10 -39
-5 0 -10 -20 -10 -44 0 -25 -4 -48 -10 -51 -5 -3 -10 -18 -10 -32 0 -15 -4
-40 -10 -57 -18 -58 -52 -211 -57 -256 -3 -25 -9 -49 -14 -55 -5 -5 -9 -28 -9
-52 0 -24 -4 -43 -10 -43 -5 0 -10 -25 -10 -55 0 -30 -4 -55 -10 -55 -5 0 -10
-25 -10 -55 0 -30 -4 -55 -9 -55 -5 0 -11 -21 -14 -47 -5 -48 -11 -79 -43
-243 -9 -47 -18 -110 -21 -140 -3 -30 -9 -59 -14 -64 -5 -6 -9 -36 -9 -68 0
-32 -4 -58 -10 -58 -6 0 -10 -30 -10 -70 0 -40 -4 -70 -10 -70 -6 0 -10 -31
-10 -74 0 -41 -4 -78 -10 -81 -5 -3 -10 -42 -10 -85 0 -43 -4 -81 -9 -84 -4
-3 -10 -47 -13 -98 -6 -114 -18 -138 -70 -138 -21 0 -38 -4 -38 -10 0 -5 -18
-10 -40 -10 -22 0 -40 -4 -40 -9 0 -5 -17 -11 -37 -14 -21 -3 -56 -10 -78 -15
-58 -15 -154 -41 -195 -53 -19 -6 -50 -13 -67 -16 -18 -3 -33 -9 -33 -14 0 -5
-20 -9 -45 -9 -25 0 -45 -4 -45 -10 0 -5 -13 -10 -30 -10 l-30 0 0 65 c0 37 4
65 10 65 6 0 10 26 10 59 0 33 5 63 10 66 6 3 10 28 10 54 0 26 4 51 9 57 5 5
12 32 14 59 7 58 19 136 42 255 9 47 19 106 22 132 3 27 9 48 14 48 5 0 9 29
9 65 0 37 4 65 10 65 6 0 10 27 10 60 0 33 4 60 10 60 6 0 10 26 10 59 0 33 4
62 9 65 5 3 11 29 15 58 8 71 20 146 41 248 9 47 19 109 22 138 3 29 9 55 14
58 5 3 9 30 9 60 0 30 5 54 10 54 6 0 10 27 10 60 0 33 4 60 10 60 6 0 10 27
10 60 0 33 4 60 10 60 6 0 10 26 10 59 0 33 5 63 10 66 6 3 10 24 10 46 0 21
-4 39 -10 39 -5 0 -10 -4 -10 -10 0 -6 -28 -10 -65 -10 -37 0 -65 -4 -65 -10
0 -6 -27 -10 -60 -10 -33 0 -60 -4 -60 -9 0 -5 -21 -12 -47 -15 -27 -3 -69 -8
-95 -12 -42 -5 -48 -4 -49 12 0 11 -5 91 -10 179 -5 88 -12 229 -15 313 -4 83
-10 152 -15 152 -5 0 -9 34 -9 75 0 68 2 75 20 75 11 0 20 5 20 10 0 6 35 10
85 10 47 0 85 4 85 9 0 5 42 11 93 15 103 7 194 17 377 41 175 23 292 35 385
42 44 2 84 9 89 14 6 5 45 9 87 9 43 0 81 5 84 10 3 6 48 10 101 10 56 0 94 4
94 10 0 6 38 10 95 10 57 0 95 4 95 10 0 6 32 10 75 10 l75 0 0 -104z"/>
<path d="M4376 3159 c-3 -35 -9 -112 -12 -171 -3 -60 -10 -108 -15 -108 -5 0
-9 -54 -9 -120 0 -73 -4 -120 -10 -120 -6 0 -10 -42 -10 -105 l0 -105 50 0
c28 0 50 -4 50 -10 0 -6 40 -10 100 -10 60 0 100 -4 100 -10 0 -6 42 -10 105
-10 58 0 105 -4 105 -9 0 -5 42 -11 93 -14 113 -7 261 -21 432 -42 186 -23
316 -36 428 -42 53 -2 97 -9 97 -14 0 -5 45 -9 101 -9 116 0 129 -7 129 -69
l0 -41 -55 0 c-30 0 -55 -4 -55 -10 0 -6 -42 -10 -105 -10 -63 0 -105 -4 -105
-10 0 -6 -42 -10 -105 -10 -58 0 -105 -4 -105 -9 0 -5 -44 -12 -97 -15 -124
-6 -278 -20 -463 -41 -209 -24 -333 -35 -435 -41 -49 -3 -94 -10 -99 -15 -6
-5 -54 -9 -107 -9 -55 0 -101 -4 -104 -10 -3 -5 -37 -10 -76 -10 l-69 0 0
-114 c0 -66 -4 -117 -10 -121 -6 -4 -10 -122 -10 -321 0 -203 4 -314 10 -314
6 0 10 -7 10 -15 0 -8 5 -15 10 -15 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0
10 -7 10 -16 0 -9 8 -24 18 -33 10 -9 35 -44 57 -78 22 -35 43 -63 48 -63 4 0
7 -8 7 -18 0 -10 9 -24 20 -32 11 -8 20 -22 20 -32 0 -10 5 -18 10 -18 6 0 10
-7 10 -15 0 -8 4 -15 9 -15 5 0 13 -9 16 -20 3 -11 12 -26 18 -32 20 -21 67
-97 67 -108 0 -5 5 -10 10 -10 6 0 10 -7 10 -16 0 -9 8 -24 18 -33 10 -9 35
-44 57 -78 47 -73 68 -79 117 -32 18 17 45 38 60 47 16 9 28 20 28 24 0 4 7 8
15 8 8 0 15 5 15 10 0 6 6 10 13 10 7 0 19 9 27 20 8 11 19 20 25 20 10 0 82
53 122 90 12 11 27 20 33 20 5 0 10 5 10 10 0 6 7 10 15 10 8 0 15 5 15 10 0
6 4 10 10 10 5 0 20 9 32 19 13 11 34 29 47 40 13 12 28 21 32 21 10 0 39 29
39 40 0 5 -11 20 -25 34 -14 14 -25 32 -25 41 0 8 -4 15 -10 15 -5 0 -10 7
-10 15 0 8 -4 15 -10 15 -5 0 -10 6 -10 13 0 6 -11 26 -25 42 -13 17 -28 35
-33 40 -4 6 -19 29 -32 53 -13 23 -27 42 -32 42 -4 0 -8 8 -8 18 0 10 -9 24
-20 32 -11 8 -20 20 -20 28 0 7 -9 22 -20 34 -10 12 -17 24 -15 28 8 13 66 22
165 27 52 2 99 9 105 14 5 5 55 9 112 9 62 0 103 4 103 10 0 6 42 10 105 10
58 0 105 4 105 9 0 5 42 12 93 15 214 12 466 41 479 54 11 11 54 251 65 360 2
28 9 52 14 52 5 0 9 29 9 65 0 37 4 65 10 65 6 0 10 28 10 65 0 37 4 65 10 65
6 0 10 26 10 59 0 33 4 62 9 65 5 3 11 32 15 63 8 74 20 156 41 263 26 137 36
201 42 263 2 31 9 57 14 57 5 0 9 29 9 65 0 37 4 65 10 65 6 0 10 13 10 28 0
28 -1 29 -82 40 -87 13 -150 18 -310 28 -54 3 -98 10 -98 15 0 5 -47 9 -105 9
-63 0 -105 4 -105 10 0 6 -43 10 -110 10 -67 0 -110 4 -110 10 0 6 -42 10
-105 10 -58 0 -105 4 -105 9 0 4 -46 11 -102 15 -121 7 -249 19 -448 42 -80 9
-188 20 -240 24 -52 4 -111 11 -132 15 l-36 7 -6 -63z"/>
<path d="M3471 2900 c1 -42 1 -43 9 -16 6 17 5 36 0 45 -7 11 -9 3 -9 -29z"/>
<path d="M8840 2900 c0 -22 5 -40 10 -40 6 0 10 18 10 40 0 22 -4 40 -10 40
-5 0 -10 -18 -10 -40z"/>
<path d="M3447 2818 c-3 -18 -13 -62 -22 -98 -9 -36 -19 -82 -22 -102 -3 -21
-9 -38 -14 -38 -5 0 -9 -20 -9 -45 0 -25 -4 -45 -10 -45 -5 0 -10 -18 -10 -40
0 -22 -4 -40 -10 -40 -5 0 -10 -20 -10 -45 0 -25 -4 -45 -9 -45 -5 0 -11 -15
-14 -32 -9 -53 -24 -119 -43 -183 -9 -33 -19 -79 -21 -102 -3 -24 -9 -43 -14
-43 -5 0 -9 -18 -9 -40 0 -22 -4 -40 -10 -40 -5 0 -10 -20 -10 -45 0 -25 -4
-45 -10 -45 -5 0 -10 -18 -10 -40 0 -22 -4 -40 -10 -40 -5 0 -10 -20 -10 -45
0 -25 -4 -45 -9 -45 -5 0 -11 -17 -14 -37 -3 -21 -10 -56 -16 -78 -25 -97 -25
-95 -2 -95 11 0 21 5 21 10 0 6 22 10 49 10 28 0 53 5 56 10 3 5 17 10 29 10
22 0 24 6 35 103 7 56 15 137 17 180 3 42 10 77 15 77 5 0 9 38 9 85 0 50 4
85 10 85 6 0 10 35 10 85 0 50 4 85 10 85 6 0 10 34 10 83 0 46 4 87 9 93 5 5
12 45 15 89 6 92 19 202 36 328 9 60 10 91 3 98 -7 7 -12 0 -16 -23z"/>
<path d="M8820 2812 c0 -23 -4 -42 -10 -42 -5 0 -10 -11 -10 -23 0 -13 -10
-59 -21 -103 -12 -43 -24 -96 -26 -116 -3 -21 -9 -38 -14 -38 -5 0 -9 -18 -9
-40 0 -22 -4 -40 -10 -40 -5 0 -10 -20 -10 -45 0 -25 -4 -45 -10 -45 -5 0 -10
-18 -10 -40 0 -22 -4 -40 -10 -40 -5 0 -10 -20 -10 -45 0 -25 -4 -45 -10 -45
-5 0 -10 -16 -10 -35 0 -19 -4 -43 -9 -53 -11 -21 -51 -183 -58 -234 -3 -21
-9 -38 -14 -38 -5 0 -9 -18 -9 -40 0 -22 -4 -40 -10 -40 -5 0 -10 -20 -10 -45
0 -25 -4 -45 -10 -45 -5 0 -10 -20 -10 -45 0 -25 -4 -45 -10 -45 -5 0 -10 -18
-10 -40 0 -22 -4 -40 -10 -40 -5 0 -10 -9 -10 -20 0 -13 7 -20 20 -20 11 0 20
5 20 10 0 6 25 10 55 10 30 0 55 5 55 10 0 6 11 10 24 10 28 0 36 28 36 126 0
36 4 64 10 64 6 0 10 35 10 85 0 50 4 85 10 85 6 0 10 35 10 85 0 50 4 85 10
85 6 0 10 35 10 85 0 47 4 85 9 85 5 0 12 35 14 78 6 100 25 263 33 282 6 14
19 126 30 259 4 39 2 67 -5 74 -8 8 -11 -1 -11 -31z"/>
<path d="M2360 1470 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>
<path d="M2341 1404 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2322 1340 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6218 3873 l-3 -238 -242 -3 -243 -2 -2 187 -3 188 -42 3 -43 3 0
-235 0 -236 -245 0 -245 0 0 145 0 145 -40 0 -40 0 0 -195 0 -195 324 0 c349
0 336 -2 336 52 0 46 11 48 299 48 l271 0 0 271 c0 293 -1 299 -54 299 l-26 0
-2 -237z"/>
</g>
</svg>

    );
};
