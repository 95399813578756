import { useEffect, useRef, useState } from "react"
import { Create, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Autocomplete, FormHelperText, FormLabel, Stack, FormControl, Typography, Grid, Avatar } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate, useNavigation, useApiUrl } from "@refinedev/core";
import { Controller } from "react-hook-form";
import axios from "axios";
import { editingStateInitializer } from "@mui/x-data-grid/internals";

export const MenuCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const [] = useState();
    const { edit } = useNavigation();
    const [coverImage, setcoverImage] = useState("")
    const apiUrl = useApiUrl();
    
    const {
        saveButtonProps,
        refineCore: { formLoading },
        register,
        control,
        formState: { errors },
        reset,
        setValue
        // handleSubmit,
    } = useForm({
        refineCoreProps: {
            action: "create", // or clone
            resource: "menus",
            redirect: false,
            onMutationSuccess: (data) => {
                edit("menus", data?.data?._id)
            }
        },
    });

    useEffect(() => {
        // The rest of your useEffect
            reset(); // Call reset on form opening
            setcoverImage(""); // Clear the image state
    }, [reset]);

    const uploadImageHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files && event.target.files.length > 0) {
    
            const file = event.target.files[0];
        
            if (file) {
                const formData = new FormData();
                formData.append('coverImage', file); // 'coverImage' is the field name expected by your server
        
                try {
                    const response = await axios.post(apiUrl + "/products/coverImage", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                
                    // Assuming the server returns the file path in the response
                    const imagePath = response.data.path; // Adjust this depending on the response structure
                
                    // Set the value in the form
                    setValue('coverImage', imagePath); 
                    setcoverImage(imagePath);
                } catch (error) {
                    console.error('Error uploading file:', error);
                    // Handle the error, e.g. show an error message
                }
            }
        }
    };
    const fileInputRef = useRef<HTMLInputElement>(null);

    const onAvatarClick = () => {
        if(fileInputRef && fileInputRef?.current) {
            fileInputRef.current.click();
        }
    };

    

    const { autocompleteProps } = useAutocomplete({
        resource: "restaurantBrands",
    });

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps} >
            <form>
                <Grid
                    container
                    marginTop="8px"
                    sx={{
                        marginX: { xs: "0px" },
                        paddingX: { xs: 1, md: 4 },
                    }}
                >
                    <FormControl sx={{ width: { md: "50%", xs: "100%"} }}>
                        <Stack
                            gap={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Avatar
                                src={apiUrl + "/" + coverImage}
                                alt="Menu image"
                                onClick={onAvatarClick}
                                sx={{
                                    width: {
                                        xs: 180,
                                        lg: 256,
                                    },
                                    height: {
                                        xs: 180,
                                        lg: 256,
                                    },
                                }}
                            />
                            <input
                                    {...register("coverImage")}
                                    accept="image/*"
                                    id="cover-image"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={uploadImageHandler}
                                    ref={fileInputRef}
                                />
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                }}
                            >
                                {translate("menus.fields.coverImage")}
                            </Typography>
                        </Stack>
                    </FormControl>
                    <Grid item xs={12} md={4}>
                        <Stack gap="24px">
                            <FormControl>
                                <FormLabel
                                    required
                                    sx={{
                                        marginBottom: "8px",
                                        fontWeight: "700",
                                        fontSize: "14px",
                                        color: "text.primary",
                                    }}
                                >
                                    {translate("menus.fields.name")}
                                </FormLabel>
                                <TextField
                                    {...register("name", {
                                        required: translate("errors.required.field", {
                                            field: "name",
                                        }),
                                    })}
                                    size="small"
                                    margin="none"
                                    variant="outlined"
                                />
                                {errors.name && (
                                    <FormHelperText error>
                                        {errors.name.message as string}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl>
                                <FormLabel
                                    required
                                    sx={{
                                        marginBottom: "8px",
                                        fontWeight: "700",
                                        fontSize: "14px",
                                        color: "text.primary",
                                    }}
                                >
                                    {translate("menus.fields.description")}
                                </FormLabel>
                                <TextField
                                    {...register("description", {
                                        required: translate("errors.required.field", {
                                            field: "description",
                                        }),
                                    })}
                                    size="small"
                                    margin="none"
                                    variant="outlined"
                                />
                                {errors.description && (
                                    <FormHelperText error>
                                        {errors.description.message as string}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {!autocompleteProps.loading &&
                            <FormControl>
                            <FormLabel
                                    required
                                    sx={{
                                        marginBottom: "8px",
                                        fontWeight: "700",
                                        fontSize: "14px",
                                        color: "text.primary",
                                    }}
                                >
                                    {translate("menus.fields.restaurantBrand")}
                                </FormLabel>
                                <Controller
                                    control={control}
                                    name="restaurantBrand"
                                    rules={{
                                        required: translate(
                                            "errors.required.field",
                                            { field: "RestaurantBrand" },
                                        ),
                                    }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            disablePortal
                                            {...autocompleteProps}
                                            {...field}
                                            onChange={(_, value) => {
                                                
                                                field.onChange(value?._id ?? '');
                                            }}
                                            getOptionLabel={(item) => {
                                                return item?.name
                                                    ? item?.name
                                                    : "";
                                            }}
                                            // // isOptionEqualToValue={(
                                            // //     option,
                                            // //     value,
                                            // // ) =>
                                            // // {
                                            // //     return value === undefined || option?._id === value?._id
                                            // // }
                                            // }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // label={translate(
                                                    //     "menus.fields.restaurantBrand",
                                                    // )}
                                                    variant="outlined"
                                                    error={
                                                        !!errors.restaurantBrand
                                                            ?.message
                                                    }
                                                    required
                                                />
                                            )}
                                        />
                                    )}
                                />
                                {errors.restaurantBrand && (
                                    <FormHelperText error>
                                        {errors.restaurantBrand.message  as string}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            }
                            
                                
                           
                        </Stack>
                    <FormControl fullWidth>
                        <FormLabel
                            required
                            sx={{
                                marginBottom: "8px",
                                fontWeight: "700",
                                fontSize: "14px",
                                color: "text.primary",
                            }}
                        >
                            {translate("menus.fields.shippingPrice")}
                        </FormLabel>
                        <TextField
                            {...register("shippingPrice", {
                                required: translate("errors.required.field", {
                                    field: "shippingPrice",
                                }),
                            })}
                            type="number"
                            margin="none"
                            required
                            fullWidth
                        />
                        {errors.shippingPrice && (
                            <FormHelperText error>
                                {errors.shippingPrice.message as string}
                            </FormHelperText>
                        )}
                    </FormControl>
                    </Grid>
                </Grid>
            </form>
        </Create>
    );
};
