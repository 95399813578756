import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

export const BikeWhiteIcon = (props: SvgIconProps) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="40" height="40" viewBox="0 0 400.000000 400.000000"
 preserveAspectRatio="xMidYMid meet"  {...props}>

<g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path style={{fill: "#C2185B"}} d="M0 2000 l0 -2000 2000 0 2000 0 0 2000 0 2000 -2000 0 -2000 0 0
-2000z m2220 1133 c0 -5 -9 -69 -20 -143 -11 -74 -24 -202 -30 -285 -15 -210
-16 -215 -46 -215 -20 0 -23 -4 -19 -22 3 -13 13 -57 23 -98 13 -57 16 -107
14 -206 l-4 -131 -56 -13 c-31 -7 -95 -23 -142 -36 -118 -33 -123 -33 -116 14
12 86 17 152 11 152 -3 0 -25 -5 -49 -11 -44 -11 -48 -18 -71 -120 -6 -25 -11
-26 -168 -51 -89 -14 -163 -25 -165 -23 -1 1 12 35 29 76 32 75 120 319 115
319 -2 0 -17 -28 -34 -62 -18 -35 -59 -93 -91 -129 -65 -72 -212 -178 -276
-199 -81 -27 -245 -6 -253 32 -1 8 18 58 43 112 25 53 45 99 45 102 0 2 7 15
16 29 15 23 16 23 52 8 47 -20 124 -12 183 17 83 42 112 87 70 110 -27 14 -71
13 -170 -6 -47 -9 -114 -14 -150 -12 -54 4 -67 8 -87 32 -31 35 -33 127 -4
205 49 135 186 310 357 457 55 47 143 84 200 84 49 0 58 -4 84 -31 39 -42 50
-91 47 -206 l-3 -97 -115 -47 -115 -46 -6 29 c-9 42 -22 51 -58 43 -62 -14
-121 -84 -121 -144 0 -40 27 -47 126 -32 171 25 235 8 268 -73 17 -39 18 -54
10 -96 -6 -27 -8 -47 -5 -44 10 9 89 253 126 384 24 89 87 295 90 298 8 9 142
33 340 63 39 6 77 12 85 14 29 5 40 5 40 -2z m-608 -1315 c-2 -73 -5 -135 -8
-137 -2 -2 -50 -7 -106 -12 -212 -17 -356 -31 -466 -45 -166 -21 -149 -31
-156 94 -4 59 -9 119 -11 134 -4 26 -2 27 48 32 82 8 259 24 412 37 115 9 211
19 280 28 6 1 9 -47 7 -131z m654 114 c-25 -79 -56 -466 -63 -783 l-6 -306
-151 -33 c-83 -18 -157 -35 -165 -38 -11 -4 -13 3 -8 34 4 21 9 66 13 98 l7
59 -44 -7 c-55 -9 -63 -18 -78 -88 -14 -65 -3 -59 -151 -83 -52 -9 -116 -19
-142 -23 l-46 -8 25 65 c14 36 29 80 34 97 5 18 30 85 55 150 25 66 60 166 79
224 83 262 120 382 145 480 l27 105 39 6 c22 4 71 12 109 18 39 7 120 20 180
30 145 25 148 25 141 3z m674 -70 c0 -38 -3 -103 -6 -144 l-7 -75 -99 -6 c-55
-3 -101 -7 -104 -9 -5 -6 -98 -492 -118 -618 -9 -58 -16 -119 -16 -136 l0 -32
-107 -26 c-60 -15 -119 -29 -132 -33 -22 -6 -23 -4 -17 28 11 65 25 145 50
299 14 85 30 180 36 210 5 30 17 99 25 153 9 54 19 105 22 113 3 8 4 14 2 14
-2 0 -40 -7 -83 -15 -44 -8 -80 -14 -81 -13 -3 4 -24 282 -22 285 2 1 23 3 47
5 37 3 46 0 55 -20 19 -36 22 -36 67 2 49 42 43 41 203 56 66 7 143 16 170 20
28 4 65 8 83 9 l32 1 0 -68z m-1434 -484 c-4 -73 -8 -133 -9 -134 -1 -1 -69
-7 -152 -13 -82 -7 -186 -16 -230 -21 -131 -14 -247 -28 -290 -35 l-41 -7 -13
132 c-11 108 -11 134 -1 140 7 5 54 11 104 14 50 4 129 11 176 16 47 5 150 14
230 20 80 7 154 13 165 14 11 2 31 4 44 5 l24 1 -7 -132z m-113 -458 l-8 -95
-100 -8 c-203 -15 -439 -39 -460 -47 -11 -4 -52 -9 -90 -11 l-70 -4 -8 129
c-6 95 -5 131 3 136 6 4 53 10 103 13 159 11 330 27 392 37 33 5 101 13 150
17 l90 8 3 -40 c1 -22 -1 -83 -5 -135z"/>
<path d="M1920 2873 c-1 -13 -62 -267 -99 -408 -29 -112 -28 -119 11 -111 17
3 33 7 34 9 3 3 27 175 44 312 17 146 22 205 16 205 -3 0 -6 -3 -6 -7z"/>
<path d="M1940 1563 c-18 -76 -39 -163 -47 -193 -8 -30 -23 -90 -33 -133 l-18
-78 36 3 36 3 12 90 c7 50 17 137 24 195 6 58 16 136 21 174 5 38 8 71 5 73
-2 3 -19 -58 -36 -134z"/>
</g>
</svg>

       
    );
};
