import React, { useState } from "react";
import axios from "axios";
import { useNotification, useApiUrl } from "@refinedev/core";
import { Switch, FormControlLabel } from "@mui/material";

interface MenuToggleSwitchProps {
    initialStatus: boolean;
}

const MenuToggleSwitch: React.FC<MenuToggleSwitchProps> = ({ initialStatus }) => {
    const [isActive, setIsActive] = useState(initialStatus);
    const notify = useNotification(); // Assuming this returns a function directly.
    const apiUrl = useApiUrl(); // Get the base API URL from the context.

    const handleToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newIsActive = event.target.checked;
        setIsActive(newIsActive);
        const endpoint = newIsActive ? `https://api.backend.saheat.tn/api/v1/menus/enable-all-menus` : 
        `https://api.backend.saheat.tn/api/v1/menus/disable-all-menus`;

        try {
            // Make the axios post request
            await axios.patch(endpoint);

        } catch (error: any) { // Handle error appropriately

            console.error('Error toggling menus', error);

            setIsActive(!newIsActive); // Revert switch position on error
        }
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={isActive}
                    onChange={handleToggle}
                    name="toggleMenuActivation"
                />
            }
            label={isActive ? "Disable All Restaurants" : "Enable All Restaurants"}
        />
    );
};

export default MenuToggleSwitch;
