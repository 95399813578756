import React, { useEffect } from "react";
import { UseModalFormReturnType } from "@refinedev/react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import { Edit } from "@refinedev/mui";
import { Autocomplete, Chip, Drawer, FormControl, IconButton, TextField, Typography } from '@mui/material';

import Box from "@mui/material/Box";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { HttpError } from "@refinedev/core/dist/interfaces";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useAutocomplete } from '@refinedev/mui';


interface EditSupplementGroupProps extends UseModalFormReturnType<HttpError> {
    menu?: string;
    allSupplements: IngredientType[]; // Assuming IngredientType includes isSupplement attribute
    supplementGroupData: SupplementGroupType;
    close: () => void;
    visible: boolean;
}

interface IngredientType {
    _id: string;
    name: string;
    isSupplement: boolean; 
}

interface SupplementGroupType {
    _id: string;
    name: string;
    description: string;
    maxSupplements: number;
    supplements: string[]; // If it only contains IDs
}



export const EditSupplementGroup: React.FC<EditSupplementGroupProps> = ({
    visible,
    close,
    allSupplements, 
    menu,
    saveButtonProps,
    refineCore: { onFinish },
    register, 
    handleSubmit, 
    control, 
    supplementGroupData,
    setValue, 
    formState: { errors },
    reset
}) => {

    useEffect(() => {
        if (menu) {
            setValue('menu', menu);
        }
    }, [menu, setValue]);

    useEffect(() => {
        if (supplementGroupData && visible) {
          // Using reset to set all form values
          reset({
            ...supplementGroupData
          });
        }
      }, [supplementGroupData, visible, reset]);

    
    return (
        <Drawer
            sx={{ zIndex: "1301" }}
            PaperProps={{ sx: { width: { sm: "100%", md: 500 } } }}
            open={visible}
            onClose={close}
            anchor="right"
        >
             <Edit
                saveButtonProps={saveButtonProps}
                headerProps={{
                    title: (<Typography>Modifier un groupe de produits</Typography>),
                    avatar: (
                        <IconButton
                            onClick={() => close()}
                            sx={{
                                width: "30px",
                                height: "30px",
                                mb: "5px",
                            }}
                        >
                            <CloseOutlined />
                        </IconButton>
                    ),
                    action: null,
                }}
                wrapperProps={{ sx: { overflowY: "scroll", height: "100vh" } }}
            >

                <form onSubmit={handleSubmit(onFinish)}>
                    <Box
                        paddingX="50px"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            paddingX: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Name"
                                {...register("name", { required: "Name is required" })}
                                error={!!errors.name}
                                // helperText={errors.name?.message}
                                variant="outlined"
                                defaultValue={supplementGroupData?.name}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Description"
                                {...register("description")}
                                multiline
                                rows={4}
                                variant="outlined"
                                defaultValue={supplementGroupData?.description}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <OutlinedInput
                                type="number"
                                {...register("maxSupplements")}
                                label="Maximum supplements"
                                placeholder="Maximum supplements"
                                defaultValue={supplementGroupData?.maxSupplements}
                            />
                        </FormControl>
                        <input
                            {...register('menu')}
                            type="hidden"
                            value={menu}
                        />

                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="supplements"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
    {...field}
    multiple
    id="supplements-select"
    options={allSupplements.filter(item => item.isSupplement)}
    getOptionLabel={(option) => option.name}
    defaultValue={supplementGroupData.supplements.length > 0 ? allSupplements.filter(item => supplementGroupData.supplements.includes(item._id)) : []}
    onChange={(_, value) => field.onChange(value.map(item => item._id))}
    isOptionEqualToValue={(option, value) => option._id === value._id}
    renderInput={(params) => (
        <TextField
            {...params}
            variant="outlined"
            label="Select Supplements"
            placeholder="Start typing to search supplements"
            error={!!errors.ingredients}
        />
    )}
/>



                                )}
                            />
                        </FormControl>

                    </Box>
                </form>
            </Edit>
        </Drawer>
    );
};