import React, { useEffect } from "react";
import { UseModalFormReturnType } from "@refinedev/react-hook-form";
import { useFormContext, Controller } from "react-hook-form";
import { Create, NumberField } from "@refinedev/mui";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { HttpError } from "@refinedev/core/dist/interfaces";
import { Autocomplete, Chip, Input, OutlinedInput, Typography } from "@mui/material";

interface CreateSupplementGroupProps extends UseModalFormReturnType<HttpError> {
    menu?: string;  
    allSupplements: IngredientType[];  
    close: () => void;
    visible: boolean;
}

interface IngredientType {
    _id: string;
    name: string;
    isSupplement: boolean; 
}

interface SupplementGroupType { 
    _id?: string;  
    name: string;
    description: string;
    maxSupplements: number;
    supplements: IngredientType[];
}


export const CreateSupplementGroup: React.FC<CreateSupplementGroupProps> = ({
    visible,
    close,
    allSupplements, 
    menu,
    saveButtonProps,
    refineCore: { onFinish },
    register, 
    handleSubmit, 
    control, 
    setValue, 
    formState: { errors }
}) => {

    useEffect(() => {
        if (menu) {
            setValue('menu', menu);
        }
    }, [menu, setValue]);


    return (
        <Drawer
            sx={{ zIndex: "1301" }}
            PaperProps={{ sx: { width: { sm: "100%", md: 500 } } }}
            open={visible}
            onClose={close}
            anchor="right"
        >
             <Create
                saveButtonProps={saveButtonProps}
                headerProps={{
                    title: (<Typography>Créer un groupe de produits</Typography>),
                    avatar: (
                        <IconButton
                            onClick={() => close()}
                            sx={{
                                width: "30px",
                                height: "30px",
                                mb: "5px",
                            }}
                        >
                            <CloseOutlined />
                        </IconButton>
                    ),
                    action: null,
                }}
                wrapperProps={{ sx: { overflowY: "scroll", height: "100vh" } }}
            >

                <form onSubmit={handleSubmit(onFinish)}>
                    <Box
                        paddingX="50px"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            paddingX: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Name"
                                {...register("name", { required: "Name is required" })}
                                error={!!errors.name}
                                // helperText={errors.name?.message}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Description"
                                {...register("description")}
                                multiline
                                rows={4}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <OutlinedInput
                                type="number"
                                {...register("maxSupplements")}
                                label="Maximum supplements"
                                placeholder="Maximum supplements"
                            />
                        </FormControl>
                        <input
                            {...register('menu')}
                            type="hidden"
                            value={menu}
                        />
                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="supplements"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        multiple
                                        id="supplements-select"
                                        options={allSupplements.filter(item => item.isSupplement)} // Ensure this matches your data structure
                                        getOptionLabel={(option) => option?.name}
                                        onChange={(_, value) => field.onChange(value)}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip label={option?.name} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select Supplements"
                                                placeholder="Start typing to search supplements"
                                            />
                                        )}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                </form>
            </Create>
        </Drawer>
    );
};