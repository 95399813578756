import React, { useEffect } from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    DeleteButton,
    List,
    TagField,
    DateField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    IResourceComponentsProps,
    useTranslate,
    useMany,
    useNotification,
} from "@refinedev/core";
import { socket } from "App";
import { format } from "date-fns";
import { Typography } from "@mui/material";
import newOrderSound from '../../sound/new_order_notif.mp3';

const orderCreatedSound = new Audio(newOrderSound);

export const RestaurantList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { dataGridProps } = useDataGrid();

    const { data: menusData, isLoading: menusIsLoading } = useMany({
        resource: "menus",
        ids: [].concat(
            ...(dataGridProps?.rows?.map((item: any) => item?.menus) ?? []),
        ),
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });

    const { data: addressData, isLoading: addressIsLoading } = useMany({
        resource: "addresses",
        ids: dataGridProps?.rows?.map((item: any) => item?.address) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });

    const notif = useNotification();

    useEffect(() => {
        socket.on('groupOrderCreated', (newOrder) => {
            if(notif.open) {
                notif.open({
                    message: 'New order received',
                    type: 'success',
                });
            }
            orderCreatedSound.play();
        });
    
        socket.on('groupOrderUpdated', (updatedOrder) => {
          
            if(notif.open) {
    
                notif.open({
                    message: 'Order updated',
                    description: `Order ${updatedOrder.groupOrderNumber} has been updated.`,
                    type: 'success',
                });
            }
           
        });
    
        socket.on(`groupOrderDeleted`, (deletedOrder) => {
          
            if(notif.open) {
    
                notif.open({
                    message: 'Order deleted',
                    description: `Order ${deletedOrder.groupOrderNumber} has been deleted.`,
                    type: 'progress',
                  });
            }
        });
    
        return () => {
          socket.off('groupOrderCreated');
          socket.off('groupOrderUpdated');
          socket.off('groupOrderDeleted');
        };
      }, []);

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "name",
                flex: 1,
                headerName: translate("restaurants.fields.name"),
                minWidth: 200,
            },
            {
                field: "description",
                flex: 1,
                headerName: translate("restaurants.fields.description"),
                minWidth: 200,
            },
            {
                field: "createdAt",
                flex: 1,
                headerName: translate("orders.fields.createdAt"),
                minWidth: 250,
                renderCell: function render({ value }) {
                    // Use `format` from date-fns to format your date-time value
                    const formattedDate = format(new Date(value), "PPpp");
                    return <Typography sx={{ fontSize: "14px" }}>{formattedDate}</Typography>;
                },
            },
            {
                field: "updatedAt",
                flex: 1,
                headerName: translate("orders.fields.updatedAt"),
                minWidth: 250,
                renderCell: function render({ value }) {
                    const formattedDate = format(new Date(value), "PPpp");
                    return <Typography sx={{ fontSize: "14px" }}>{formattedDate}</Typography>;
                },
            },
            {
                field: "address",
                flex: 1,
                headerName: translate("restaurants.fields.address"),
                minWidth: 300,
            },
            {
                field: "restaurantOwner",
                flex: 1,
                headerName: translate("restaurants.fields.restaurantOwner"),
                minWidth: 200,
            },
            {
                field: "status",
                headerName: "Status",
                width: 100,
                renderCell: (params) => params.value ? 'Active' : 'Inactive',
            },
            {
                field: "openAt",
                headerName: "Open At",
                width: 150,
                renderCell: (params) => params.value ? format(new Date(params.value), "PPpp") : '',
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row._id} />
                            <ShowButton hideText recordItemId={row._id} />
                            <DeleteButton hideText recordItemId={row._id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [translate, menusData?.data, addressData?.data],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight getRowId={row => row._id}/>
        </List>
    );
};
