import React from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    DeleteButton,
    List,
    DateField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    IResourceComponentsProps,
    useTranslate,
    useMany,
} from "@refinedev/core";
import { format } from "date-fns";
import { Typography } from "@mui/material";

export const ProductList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { dataGridProps } = useDataGrid();

    const { data: menuData, isLoading: menuIsLoading } = useMany({
        resource: "menus",
        ids: dataGridProps?.rows?.map((item: any) => item?.menu?.id) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "name",
                flex: 1,
                headerName: translate("products.fields.name"),
                minWidth: 200,
            },
            {
                field: "description",
                flex: 1,
                headerName: translate("products.fields.description"),
                minWidth: 200,
            },
            {
                field: "price",
                flex: 1,
                headerName: translate("products.fields.price"),
                type: "number",
                minWidth: 200,
            },
            {
                field: "category",
                flex: 1,
                headerName: translate("products.fields.category"),
                valueGetter: ({ row }) => {
                    const value = row?.category?.name;

                    return value;
                },
                minWidth: 300,
            },
            {
                field: "ingredients",
                flex: 1,
                headerName: translate("products.fields.ingredients"),
                valueGetter: ({ row }) => {
                    const value = row?.ingredients?.name;

                    return value;
                },
                minWidth: 300,
            },
            {
                field: "coverImage",
                flex: 1,
                headerName: translate("products.fields.coverImage"),
                minWidth: 100,
                renderCell: function render({ value }) {
                    return (
                        <img
                            src={value}
                            style={{ height: "50px", maxWidth: "100px" }}
                        />
                    );
                },
            },
            {
                field: "discount",
                flex: 1,
                headerName: translate("products.fields.discount"),
                valueGetter: ({ row }) => {
                    const value = row?.discount?.name;

                    return value;
                },
                minWidth: 200,
            },
            {
                field: "createdAt",
                flex: 1,
                headerName: translate("orders.fields.createdAt"),
                minWidth: 250,
                renderCell: function render({ value }) {
                    // Use `format` from date-fns to format your date-time value
                    const formattedDate = format(new Date(value), "PPpp");
                    return <Typography sx={{ fontSize: "14px" }}>{formattedDate}</Typography>;
                },
            },
            {
                field: "updatedAt",
                flex: 1,
                headerName: translate("orders.fields.updatedAt"),
                minWidth: 250,
                renderCell: function render({ value }) {
                    const formattedDate = format(new Date(value), "PPpp");
                    return <Typography sx={{ fontSize: "14px" }}>{formattedDate}</Typography>;
                },
            },
            {
                field: "menu",
                flex: 1,
                headerName: translate("products.fields.menu"),
                valueGetter: ({ row }) => {
                    const value = row?.menu?.id;

                    return value;
                },
                minWidth: 300,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row._id} />
                            <ShowButton hideText recordItemId={row._id} />
                            <DeleteButton hideText recordItemId={row._id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [translate, menuData?.data],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight getRowId={row => row._id}/>
        </List>
    );
};
