import { Create } from "@refinedev/mui";
import { Box, Checkbox, FormControlLabel, MenuItem, Select, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Controller } from "react-hook-form";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        saveButtonProps,
        refineCore: { formLoading },
        register,
        control,
        formState: { errors },
    } = useForm();

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <Controller
                    control={control}
                    name="isFirstTime"
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <FormControlLabel
                            label={translate("users.fields.isFirstTime")}
                            control={
                                <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onChange={(event) => {
                                        field.onChange(event.target.checked);
                                    }}
                                />
                            }
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="status"
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <FormControlLabel
                            label={translate("users.fields.status")}
                            control={
                                <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onChange={(event) => {
                                        field.onChange(event.target.checked);
                                    }}
                                />
                            }
                        />
                    )}
                />
                <TextField
                    {...register("first_name", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.first_name}
                    helperText={(errors as any)?.first_name?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("users.fields.first_name")}
                    name="first_name"
                />
                <TextField
                    {...register("last_name", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.last_name}
                    helperText={(errors as any)?.last_name?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("users.fields.last_name")}
                    name="last_name"
                />
                <TextField
                    {...register("email", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.email}
                    helperText={(errors as any)?.email?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="email"
                    label={translate("users.fields.email")}
                    name="email"
                />
                <TextField
                    {...register("phone_number", {
                        required: "This field is required",
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.phone_number}
                    helperText={(errors as any)?.phone_number?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate("users.fields.phone_number")}
                    name="phone_number"
                />
                    <Controller
                        name="role"
                        control={control}
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                            <Select
                                label={"role"}
                                {...field}
                                error={!!errors.role}
                            >
                                <MenuItem value="customer">Customer</MenuItem>
                                <MenuItem value="admin">Admin</MenuItem>
                                <MenuItem value="dispatcher">Dispatcher</MenuItem>
                                <MenuItem value="restaurant_owner">Restaurant Owner</MenuItem>
                                <MenuItem value="delivery_guy">Delivery Guy</MenuItem>
                            </Select>
                        )}
                    />
                <TextField
                    {...register("password", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.password}
                    helperText={(errors as any)?.password?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("users.fields.password")}
                    name="password"
                />
                <TextField
                    {...register("fcmToken", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.fcmToken}
                    helperText={(errors as any)?.fcmToken?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    multiline
                    label={translate("users.fields.fcmToken")}
                    name="fcmToken"
                />
            </Box>
        </Create>
    );
};
