import React from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    DeleteButton,
    List,
    TagField,
    DateField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    IResourceComponentsProps,
    useTranslate,
    useMany,
} from "@refinedev/core";
import { Typography } from "@mui/material";
import { format } from "date-fns";

export const ProductGroupList: React.FC<IResourceComponentsProps & { menuId?: string, rows?: any[] }> = ({ menuId, rows }) => {
    const translate = useTranslate();
    const { dataGridProps } = useDataGrid();
    dataGridProps.rows = rows? rows: dataGridProps.rows;

    const { data: menuData, isLoading: menuIsLoading } = useMany({
        resource: "menus",
        ids: dataGridProps?.rows?.map((item: any) => item?.menu) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "name",
                flex: 1,
                headerName: translate("productGroups.fields.name"),
                minWidth: 200,
            },
            {
                field: "description",
                flex: 1,
                headerName: translate("productGroups.fields.description"),
                minWidth: 200,
            },
            {
                field: "menu",
                flex: 1,
                headerName: translate("productGroups.fields.menu"),
                minWidth: 300,
                renderCell: function render({ value }) {
                    return menuIsLoading ? (
                        <>Loading...</>
                    ) : (
                        menuData?.data?.find((item) => item.id === value)?.name
                    );
                },
            },
            // {
            //     field: "products",
            //     flex: 1,
            //     headerName: translate("productGroups.fields.products"),
            //     minWidth: 300,
            //     renderCell: function render({ value }) {
            //         return productsIsLoading ? (
            //             <>Loading...</>
            //         ) : (
            //             <>
            //                 {value?.map((item: any, index: number) => (
            //                     <TagField
            //                         key={index}
            //                         value={
            //                             productsData?.data?.find(
            //                                 (resourceItems) =>
            //                                     resourceItems._id === item?.name,
            //                             )?.name
            //                         }
            //                     />
            //                 ))}
            //             </>
            //         );
            //     },
            // },
            {
                field: "createdAt",
                flex: 1,
                headerName: translate("orders.fields.createdAt"),
                minWidth: 250,
                renderCell: function render({ value }) {
                    // Use `format` from date-fns to format your date-time value
                    const formattedDate = format(new Date(value), "PPpp");
                    return <Typography sx={{ fontSize: "14px" }}>{formattedDate}</Typography>;
                },
            },
            {
                field: "updatedAt",
                flex: 1,
                headerName: translate("orders.fields.updatedAt"),
                minWidth: 250,
                renderCell: function render({ value }) {
                    const formattedDate = format(new Date(value), "PPpp");
                    return <Typography sx={{ fontSize: "14px" }}>{formattedDate}</Typography>;
                },
            },
            {
                field: "__v",
                flex: 1,
                headerName: translate("productGroups.fields.__v"),
                type: "number",
                minWidth: 200,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row._id} />
                            <ShowButton hideText recordItemId={row._id} />
                            <DeleteButton hideText recordItemId={row._id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [translate, menuData?.data],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight getRowId={row => row._id}/>
        </List>
    );
};
