import { useTranslate } from "@refinedev/core";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import type { ChipProps } from "@mui/material/Chip";

type OrderStatusProps = {
    status?: "Pending" | "Confirmed" | "Shipped" | "Delivered" | "Cancelled";
  };
  

export const OrderStatus: React.FC<OrderStatusProps> = ({ status }) => {
    const t = useTranslate();
    const { palette } = useTheme();
  
    let color: ChipProps["color"];
  
    switch (status) {
      case "Pending":
        color = "warning";
        break;
      case "Confirmed":
        color = palette.mode === "dark" ? "default" : "secondary";
        break;
      case "Shipped":
        color = "info";
        break;
      case "Delivered":
        color = "success";
        break;
      case "Cancelled":
        color = "error";
        break;
      default:
        color = "default"; // Set a default color for unknown status
        break;
    }

    return (
        <Chip
            variant="outlined"
            size="small"
            color={color}
            label={t(`enum.orderStatuses.${status}`)}
        />
    );
};
