import { Edit } from "@refinedev/mui";
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
    } = useForm();

    const roles = ["customer", "delivery_guy", "admin", "dispatcher"];

    const usersData = queryResult?.data?.data;

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <Controller
                    control={control}
                    name="isFirstTime"
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <FormControlLabel
                            label={translate("users.fields.isFirstTime")}
                            control={
                                <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onChange={(event) => {
                                        field.onChange(event.target.checked);
                                    }}
                                />
                            }
                        />
                    )}
                />
                <TextField
                    {...register("first_name", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.first_name}
                    helperText={(errors as any)?.first_name?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("users.fields.first_name")}
                    name="first_name"
                />
                <TextField
                    {...register("last_name", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.last_name}
                    helperText={(errors as any)?.last_name?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("users.fields.last_name")}
                    name="last_name"
                />
                <TextField
                    {...register("email", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.email}
                    helperText={(errors as any)?.email?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="email"
                    label={translate("users.fields.email")}
                    name="email"
                />
                <TextField
                    {...register("phone_number", {
                        required: "This field is required",
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.phone_number}
                    helperText={(errors as any)?.phone_number?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate("users.fields.phone_number")}
                    name="phone_number"
                />
                <Controller
                    name="role"
                    control={control}
                    defaultValue=""
                    rules={{ required: "This field is required" }}
                    render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="role-select-label">
                                {translate("users.fields.role")}
                            </InputLabel>
                            <Select
                                labelId="role-select-label"
                                id="role-select"
                                label={translate("users.fields.role")}
                                {...field}
                                error={!!error}
                                // helpertext={error?.message}
                            >
                                {roles.map((role, index) => (
                                    <MenuItem key={index} value={role}>
                                        {role}
                                    </MenuItem>
                                ))}
                            </Select>
                            {error && <p style={{ color: 'red', marginTop: 4 }}>{error.message}</p>}
                        </FormControl>
                    )}
                />
            </Box>
        </Edit>
    );
};
