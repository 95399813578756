import { Edit, useAutocomplete } from "@refinedev/mui";
import {
    Box,
    Checkbox,
    FormControlLabel,
    TextField,
    Autocomplete,
} from "@mui/material";

import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Controller } from "react-hook-form";
import { DateTimePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

export const RestaurantEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
    } = useForm();

    const restaurantsData = queryResult?.data?.data;

    const { autocompleteProps: addressAutocompleteProps } = useAutocomplete({
        resource: "addresses",
        defaultValue: restaurantsData?.address,
    });

    const { autocompleteProps: menuAutocompleteProps } = useAutocomplete({
        resource: "menus",
        defaultValue: restaurantsData?.menu,
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <Controller
                    control={control}
                    name="status"
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <FormControlLabel
                            label={translate("restaurants.fields.status")}
                            control={
                                <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onChange={(event) => {
                                        field.onChange(event.target.checked);
                                    }}
                                />
                            }
                        />
                    )}
                />
                <Controller
                    name="openAt"
                    control={control}
                    defaultValue={restaurantsData?.openAt}
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Open At"
                                value={field.value}
                                onChange={field.onChange}
                                // renderInput={(params: any) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    )}
                />
                <TextField
                    {...register("name", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.name}
                    helperText={(errors as any)?.name?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("restaurants.fields.name")}
                    name="name"
                />
                <TextField
                    {...register("phone", {
                        required: "This field is required",
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.phone}
                    helperText={(errors as any)?.phone?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate("restaurants.fields.phone")}
                    name="phone"
                />
                <Controller
                    control={control}
                    name="address"
                    rules={{ required: "This field is required" }}
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <Autocomplete
                            {...addressAutocompleteProps}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value?._id ?? value);
                            }}
                            getOptionLabel={(item) => {
                                return (
                                    addressAutocompleteProps?.options?.find(
                                        (p) =>
                                            p?._id?.toString() ===
                                            (item?._id ?? item)?.toString(),
                                    )?.street ?? ""
                                );
                            }}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined ||
                                option?._id?.toString() ===
                                    (value?._id ?? value)?.toString()
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translate(
                                        "restaurants.fields.address",
                                    )}
                                    margin="normal"
                                    variant="outlined"
                                    error={!!(errors as any)?.address}
                                    helperText={
                                        (errors as any)?.address?.message
                                    }
                                    required
                                />
                            )}
                        />
                    )}
                />
             
                <TextField
                    {...register("deliveryPrice", {
                        required: "This field is required",
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.deliveryPrice}
                    helperText={(errors as any)?.deliveryPrice?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate("restaurants.fields.deliveryPrice")}
                    name="deliveryPrice"
                />
                {/*
                    DatePicker component is not included in "@refinedev/mui" package.
                    To use a <DatePicker> component, you can follow the official documentation for Material UI.

                    Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                */}
                <TextField
                    {...register("createdAt", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.createdAt}
                    helperText={(errors as any)?.createdAt?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label={translate("restaurants.fields.createdAt")}
                    name="createdAt"
                />
                

                {/*
                    DatePicker component is not included in "@refinedev/mui" package.
                    To use a <DatePicker> component, you can follow the official documentation for Material UI.

                    Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                */}
                <TextField
                    {...register("updatedAt", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.updatedAt}
                    helperText={(errors as any)?.updatedAt?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label={translate("restaurants.fields.updatedAt")}
                    name="updatedAt"
                />
            </Box>
        </Edit>
    );
};
