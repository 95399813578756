import { AuthPage } from "@refinedev/mui";
import { BikeWhiteIcon } from "components/icons";

export const Login = () => {
  return (
    <AuthPage
      type="login"
      title={<div
        style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "16px",
            fontSize: "20px",
        }}
    >
        <BikeWhiteIcon /> 
    </div>}
      formProps={{
        defaultValues: { email: "", password: "" },
      }}
    />
  );
};
