import React, { useEffect } from "react";
import axios from "axios";
import { useTranslate, useApiUrl, HttpError } from "@refinedev/core";
import { UseModalFormReturnType } from "@refinedev/react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import { Create, useAutocomplete } from "@refinedev/mui";

import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";

import CloseOutlined from "@mui/icons-material/CloseOutlined";

interface EditSupplementProps extends UseModalFormReturnType<HttpError> {
    menu?: any;
    supplementData: any,
    close: () => void;
    visible: boolean; // Define the type properly according to what 'menu' should be
}

interface SupplementType {
    _id: string; // Substitute 'string' with correct type
    name: string;
    description: string;
    isActive: boolean;
    eatSahiAllowed: boolean;
    isSupplement: boolean;
    priceAsSupplement: number;
    calories: number;
    proteine: number;
    carbs: number;
    fat: number;
  }

export const EditSupplement: React.FC<
 EditSupplementProps
> = ({
    watch,
    setValue,
    register,
    formState: { errors },
    control,
    supplementData,
    refineCore: { onFinish },
    handleSubmit,
    visible, 
    close,
    saveButtonProps,
    menu,
    getValues,
    reset
}) => {
    const t = useTranslate();

    const apiUrl = useApiUrl();

    const { autocompleteProps } = useAutocomplete({
        resource: "categories",
    });

    useEffect(() => {
        if (menu) {
            setValue('menu', menu); // Make sure 'menu' corresponds to the correct field name
        }
    }, [menu, setValue]);

    useEffect(() => {
        if (supplementData && visible) {
          // Using reset to set all form values
          reset({
            ...supplementData
          });
        }
      }, [supplementData, visible, reset]);


const uploadImageHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.files && event.target.files.length > 0) {

        const file = event.target.files[0];
    
        if (file) {
            const formData = new FormData();
            formData.append('coverImage', file); // 'coverImage' is the field name expected by your server
    
            try {
                const response = await axios.post(apiUrl + "/products/coverImage", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            
                // Assuming the server returns the file path in the response
                const imagePath = response.data.path; // Adjust this depending on the response structure
            
                // Set the value in the form
                setValue('coverImage', imagePath); 
                console.log(imagePath)
            } catch (error) {
                console.error('Error uploading file:', error);
                // Handle the error, e.g. show an error message
            }
        }
    }
};

    return (
        <Drawer
            sx={{ zIndex: "1301" }}
            PaperProps={{ sx: { width: { sm: "100%", md: 500 } } }}
            open={visible}
            onClose={close}
            anchor="right"
        >
            <Create
                saveButtonProps={saveButtonProps}
                headerProps={{
                    title: (<Typography>Modifier un supplement</Typography>),
                    avatar: (
                        <IconButton
                            onClick={() => close()}
                            sx={{
                                width: "30px",
                                height: "30px",
                                mb: "5px",
                            }}
                        >
                            <CloseOutlined />
                        </IconButton>
                    ),
                    action: null,
                }}
                wrapperProps={{ sx: { overflowY: "scroll", height: "100vh" } }}
            >
                <Stack>
                    <Box
                        paddingX="50px"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            paddingX: {
                                xs: 1,
                                md: 6,
                            },
                        }}
                    >
                        <form onSubmit={handleSubmit(onFinish)}>
                            <FormControl sx={{ width: "100%" }}>
                                <Stack
                                        display="flex"
                                        alignItems="center"
                                        border="1px dashed  "
                                        borderColor="primary.main"
                                        borderRadius="5px"
                                        padding="10px"
                                        marginTop="5px"
                                    >
                                    {/* Image upload input */}
                                    <Avatar
                                                sx={{
                                                    cursor: "pointer",
                                                    width: {
                                                        xs: 100,
                                                        md: 180,
                                                    },
                                                    height: {
                                                        xs: 100,
                                                        md: 180,
                                                    },
                                                }}
                                                src={apiUrl + "/" + getValues("coverImage")}
                                                alt="Product image"
                                            />
                                    <input
                                        {...register("coverImage")}
                                        accept="image/*"
                                        id="cover-image"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={uploadImageHandler}
                                    />
                                    <FormLabel htmlFor="cover-image">
                                        {t("products.fields.coverImage")}
                                    </FormLabel>
                                    {/* ... */}

                                </Stack>
                            </FormControl>
                            <Stack gap="10px" marginTop="10px">
                                <FormControl>
                                    <FormLabel required>
                                        {t("products.fields.name")}
                                    </FormLabel>
                                    <OutlinedInput
                                        id="name"
                                        {...register("name", {
                                            required: t(
                                                "errors.required.field",
                                                { field: "Name" },
                                            ),
                                        })}
                                        style={{ height: "40px" }}
                                        defaultValue={supplementData?.name}
                                    />
                                    {/* {errors.name && (
                                        <FormHelperText error>
                                            {errors.name.message}
                                        </FormHelperText>
                                    )} */}
                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        {t("products.fields.description")}
                                    </FormLabel>
                                    <OutlinedInput
                                        id="description"
                                        {...register("description")}
                                        defaultValue={supplementData?.description}
                                        multiline
                                        minRows={5}
                                        maxRows={5}
                                    />
                                    {/* {errors.description && (
                                        <FormHelperText error>
                                            {errors.description.message}
                                        </FormHelperText>
                                    )} */}
                                </FormControl>
                                <FormControl>
                                    <FormLabel required>
                                        {t("products.fields.priceAsSupplement")}
                                    </FormLabel>
                                    <OutlinedInput
                                        id="priceAsSupplement"
                                        {...register("priceAsSupplement", {
                                            required: t(
                                                "errors.required.field",
                                                { field: "PriceAsSupplement" },
                                            ),
                                        })}
                                        type="number"
                                        style={{
                                            width: "150px",
                                            height: "40px",
                                        }}
                                        defaultValue={supplementData?.priceAsSupplement}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                DT
                                            </InputAdornment>
                                        }
                                    />
                                    {/* {errors.price && (
                                        <FormHelperText error>
                                            {errors.price.message}
                                        </FormHelperText>
                                    )} */}
                                </FormControl>
                                
                                <FormControl>
                                    <FormLabel
                                        sx={{ marginTop: "10px" }}
                                        required
                                    >
                                        {t("products.fields.isActive")}
                                    </FormLabel>
                                    <Controller
                                        control={control}
                                        {...register("isActive")}
                                        defaultValue={supplementData?.isActive}
                                        render={({ field }) => (
                                            <RadioGroup
                                                id="isActive"
                                                {...field}
                                                onChange={(event) => {
                                                    const value =
                                                        event.target.value ===
                                                        "true";

                                                    setValue(
                                                        "isActive",
                                                        value,
                                                        {
                                                            shouldValidate:
                                                                true,
                                                        },
                                                    );

                                                    return value;
                                                }}
                                                row
                                            >
                                                <FormControlLabel
                                                    value={true}
                                                    control={<Radio />}
                                                    label={t("status.enable")}
                                                />
                                                <FormControlLabel
                                                    value={false}
                                                    control={<Radio />}
                                                    label={t("status.disable")}
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                    {/* {errors.isActive && (
                                        <FormHelperText error>
                                            {errors.isActive.message}
                                        </FormHelperText>
                                    )} */}
                                </FormControl>
                                {/* Missing Fields */}
                                <FormControl>
                                <FormLabel>
                                    {t("products.fields.eatSahiAllowed")}
                                </FormLabel>
                                <Controller
                                    control={control}
                                    {...register("eatSahiAllowed")}
                                    defaultValue={supplementData?.eatSahiAllowed}
                                    render={({ field }) => (
                                    <RadioGroup
                                        id="eatSahiAllowed"
                                        {...field}
                                        onChange={(event) => {
                                        const value = event.target.value === "true";

                                        setValue("eatSahiAllowed", value, {
                                            shouldValidate: true,
                                        });

                                        return value;
                                        }}
                                        row
                                    >
                                        <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label={t("status.enable")}
                                        />
                                        <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label={t("status.disable")}
                                        />
                                    </RadioGroup>
                                    )}
                                />
                                </FormControl>
                                <FormControl>
                                <FormLabel>
                                    {t("products.fields.isSupplement")}
                                </FormLabel>
                                <Controller
                                    control={control}
                                    {...register("isSupplement")}
                                    defaultValue={supplementData?.isSupplement}
                                    render={({ field }) => (
                                    <RadioGroup
                                        id="isSupplement"
                                        {...field}
                                        onChange={(event) => {
                                        const value = event.target.value === "true";

                                        setValue("isSupplement", value, {
                                            shouldValidate: true,
                                        });

                                        return value;
                                        }}
                                        row
                                    >
                                        <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label={t("status.enable")}
                                        />
                                        <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label={t("status.disable")}
                                        />
                                    </RadioGroup>
                                    )}
                                />
                                </FormControl>

                                <FormControl>
                                <FormLabel>{t("products.fields.calories")}</FormLabel>
                                <OutlinedInput
                                    id="calories"
                                    {...register("calories")}
                                    type="number"
                                    style={{
                                    width: "150px",
                                    height: "40px",
                                    }}
                                    defaultValue={supplementData?.calories}
                                />
                                </FormControl>

                                <FormControl>
                                <FormLabel>{t("products.fields.proteine")}</FormLabel>
                                <OutlinedInput
                                    id="proteine"
                                    {...register("proteine")}
                                    type="number"
                                    style={{
                                    width: "150px",
                                    height: "40px",
                                    }}
                                    defaultValue={supplementData?.proteine}
                                />
                                </FormControl>

                                <FormControl>
                                <FormLabel>{t("products.fields.fat")}</FormLabel>
                                <OutlinedInput
                                    id="fat"
                                    {...register("fat")}
                                    type="number"
                                    style={{
                                    width: "150px",
                                    height: "40px",
                                    }}
                                    defaultValue={supplementData?.fat}
                                />
                                </FormControl>

                                <FormControl>
                                <FormLabel>{t("products.fields.carbs")}</FormLabel>
                                <OutlinedInput
                                    id="carbs"
                                    {...register("carbs")}
                                    type="number"
                                    style={{
                                    width: "150px",
                                    height: "40px",
                                    }}
                                    defaultValue={supplementData?.carbs}
                                />
                                </FormControl>
                            </Stack>
                        </form>
                    </Box>
                </Stack>
            </Create>
        </Drawer>
    );
};
