import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useTranslate, useApiUrl, HttpError } from "@refinedev/core";
import { UseModalFormReturnType } from "@refinedev/react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import { Create, Edit, useAutocomplete } from "@refinedev/mui";

import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";

import CloseOutlined from "@mui/icons-material/CloseOutlined";

interface EditProductProps extends UseModalFormReturnType<HttpError> {
    menu?: any;
    close: () => void;
    visible: boolean; // Define the type properly according to what 'menu' should be
    productData: any;
}

interface Ingredient {
    _id: string;
    name: string;
  }
  

export const EditProduct: React.FC<
 EditProductProps
> = ({
    watch,
    setValue,
    register,
    formState: { errors },
    control,
    refineCore: { onFinish },
    handleSubmit,
    visible, 
    close,
    saveButtonProps,
    productData,
    menu,
    getValues,
}) => {
    const t = useTranslate();

    const apiUrl = useApiUrl();
    const [coverImage, setcoverImage] = useState("")

    
    const { autocompleteProps } = useAutocomplete({
        resource: "categories",
    });

    const currentMenuId = productData.menu; 
    const { autocompleteProps: ingredientsAutocompleteProps } = useAutocomplete({
        resource: "ingredients",
    });

    const filteredIngredients = ingredientsAutocompleteProps.options.filter(
        (ingredient) => !ingredient.isSupplement && ingredient.menu === currentMenuId
    );
    
    useEffect(() => {
        if (productData && productData.category) {
            console.log("Categories Data:", productData.category);
            setValue('category', productData.category);
        }
    }, [productData, setValue]);
    
    useEffect(() => {
        if (productData && productData.ingredients) {
            console.log("Ingredients Data:", productData.ingredients);
            const ingredientValues = productData.ingredients.map((ingredient: Ingredient) => ({
                _id: ingredient._id,
                name: ingredient.name,
            }));
            setValue('ingredients', ingredientValues);
        }
    }, [productData, setValue]);
    
      
      
    
    useEffect(() => {
        if (productData){
        setcoverImage(productData.coverImage);
        setValue('ingredients', productData.ingredients); 
        setValue('isActive', productData.isActive);
        setValue('eatSahiAllowed', productData.eatSahiAllowed);
        setValue('calories', productData.calories);
        setValue('proteine', productData.proteine);
        setValue('fat', productData.fat);
        setValue('carbs', productData.carbs);
        setValue('menu', productData.menu); 
        }
    }, [productData]);
    
    useEffect(() => {
        if (menu) {
            setValue('menu', menu); 
        }
    }, [menu, setValue]);
 



    // Inside your component definition
    const fileInputRef = useRef<HTMLInputElement>(null);

// Handler that simulates click on file input when Avatar is clicked
const onAvatarClick = () => {
    if(fileInputRef && fileInputRef?.current) {
        fileInputRef.current.click();
    }
};

const uploadImageHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.files && event.target.files.length > 0) {

        const file = event.target.files[0];
    
        if (file) {
            const formData = new FormData();
            formData.append('coverImage', file); 
    
            try {
                const response = await axios.post(apiUrl + "/products/coverImage", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            
                const imagePath = response.data.path; 
            
                setValue('coverImage', imagePath); 
                setcoverImage(imagePath);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    }
};

    return (
        <Drawer
            sx={{ zIndex: "1301" }}
            PaperProps={{ sx: { width: { sm: "100%", md: 500 } } }}
            open={visible}
            onClose={close}
            anchor="right"
        >
            <Edit
                saveButtonProps={saveButtonProps}
                headerProps={{
                    avatar: (
                        <IconButton
                            onClick={() => close()}
                            sx={{
                                width: "30px",
                                height: "30px",
                                mb: "5px",
                            }}
                        >
                            <CloseOutlined />
                        </IconButton>
                    ),
                    action: null,
                }}
                wrapperProps={{ sx: { overflowY: "scroll", height: "100vh" } }}
            >
                <Stack>
                    <Box
                        paddingX="50px"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            paddingX: {
                                xs: 1,
                                md: 6,
                            },
                        }}
                    >
                        <form onSubmit={handleSubmit(onFinish)}>

                            <FormControl sx={{ width: "100%" }}>
                                <Stack
                                        display="flex"
                                        alignItems="center"
                                        border="1px dashed  "
                                        borderColor="primary.main"
                                        borderRadius="5px"
                                        padding="10px"
                                        marginTop="5px"
                                    >
                                    {/* Image upload input */}
                                    <Avatar
                                                sx={{
                                                    cursor: "pointer",
                                                    width: {
                                                        xs: 100,
                                                        md: 180,
                                                    },
                                                    height: {
                                                        xs: 100,
                                                        md: 180,
                                                    },
                                                }}
                                                src={apiUrl + "/" + coverImage}
                                                alt="Product image"
                                                onClick={onAvatarClick}
                                            />
                                    <input
                                        {...register("coverImage")}
                                        accept="image/*"
                                        id="cover-image"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={uploadImageHandler}
                                        ref={fileInputRef}
                                    />
                                    <FormLabel htmlFor="cover-image">
                                        {t("products.fields.coverImage")}
                                    </FormLabel>
                                    {/* ... */}

                                </Stack>
                            </FormControl>
                            <Stack gap="10px" marginTop="10px">
                                <FormControl>
                                    <FormLabel required>
                                        {t("products.fields.name")}
                                    </FormLabel>
                                    <OutlinedInput
                                        id="name"
                                        {...register("name", {
                                            required: t(
                                                "errors.required.field",
                                                { field: "Name" },
                                            ),
                                        })}
                                        style={{ height: "40px" }}
                                        defaultValue={getValues("name")}
                                    />
                                    {/* {errors.name && (
                                        <FormHelperText error>
                                            {errors.name.message}
                                        </FormHelperText>
                                    )} */}
                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        {t("products.fields.description")}
                                    </FormLabel>
                                    <OutlinedInput
                                        id="description"
                                        {...register("description")}
                                        multiline
                                        minRows={5}
                                        maxRows={5}
                                    />
                                    {/* {errors.description && (
                                        <FormHelperText error>
                                            {errors.description.message}
                                        </FormHelperText>
                                    )} */}
                                </FormControl>
                                <FormControl>
                                    <FormLabel required>
                                        {t("products.fields.price")}
                                    </FormLabel>
                                    <OutlinedInput
                                        id="price"
                                        {...register("price", {
                                            required: t(
                                                "errors.required.field",
                                                { field: "Price" },
                                            ),
                                        })}
                                        type="number"
                                        style={{
                                            width: "150px",
                                            height: "40px",
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                DT
                                            </InputAdornment>
                                        }
                                    />
                                    {/* {errors.price && (
                                        <FormHelperText error>
                                            {errors.price.message}
                                        </FormHelperText>
                                    )} */}
                                </FormControl>
                                <FormControl>
                                    <Controller
                                        control={control}
                                        name="category"
                                        render={({ field }) => (
                                <Autocomplete
                                    disablePortal
                                    {...autocompleteProps}
                                    {...field}
                                    value={field.value} 
                                    onChange={(_, value) => field.onChange(value)}
                                    getOptionLabel={(item) => item?.name ?? ""}
                                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Category"
                                            variant="outlined"
                                            error={!!errors.category?.message}
                                            required
                                        />
                                    )}
                                />

                                        )}
                                    />
                                    {/* {errors.category && (
                                        <FormHelperText error>
                                            {errors.category.message}
                                        </FormHelperText>
                                    )} */}
                                </FormControl>
                                <FormControl>
                                    <Controller
                                        control={control}
                                        name="ingredients"
                                        render={({ field }) => (
                                            <Autocomplete
                                                disablePortal
                                                multiple
                                                {...field} // this is coming from react-hook-form's Controller
                                                options={filteredIngredients} // your filtered list of ingredients
                                                getOptionLabel={(ingredient) => ingredient.name || ""}
                                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                                onChange={(_, value) => field.onChange(value)}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Ingredients"
                                                    variant="outlined"
                                                    error={!!errors.ingredients}
                                                    required
                                                  />
                                                )}
                                            />


                                        )}
                                    />
                                    {/* {errors.category && (
                                        <FormHelperText error>
                                            {errors.category.message}
                                        </FormHelperText>
                                    )} */}
                                </FormControl>
                                <FormControl>
                                    <FormLabel
                                        sx={{ marginTop: "10px" }}
                                        required
                                    >
                                        {t("products.fields.isActive")}
                                    </FormLabel>
                                    <Controller
                                        control={control}
                                        {...register("isActive")}
                                        defaultValue={getValues("isActive")}
                                        render={({ field }) => (
                                            <RadioGroup
                                                id="isActive"
                                                {...field}
                                                onChange={(event) => {
                                                    const value =
                                                        event.target.value ===
                                                        "true";

                                                    setValue(
                                                        "isActive",
                                                        value,
                                                        {
                                                            shouldValidate:
                                                                true,
                                                        },
                                                    );

                                                    return value;
                                                }}
                                                row
                                            >
                                                <FormControlLabel
                                                    value={true}
                                                    control={<Radio />}
                                                    label={t("status.enable")}
                                                />
                                                <FormControlLabel
                                                    value={false}
                                                    control={<Radio />}
                                                    label={t("status.disable")}
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                    {/* {errors.isActive && (
                                        <FormHelperText error>
                                            {errors.isActive.message}
                                        </FormHelperText>
                                    )} */}
                                </FormControl>
                                {/* Missing Fields */}
                                <FormControl>
                                <FormLabel>
                                    {t("products.fields.eatSahiAllowed")}
                                </FormLabel>
                                <Controller
                                    control={control}
                                    {...register("eatSahiAllowed")}
                                    defaultValue={getValues("eatSahiAllowed")}
                                    render={({ field }) => (
                                    <RadioGroup
                                        id="eatSahiAllowed"
                                        {...field}
                                        onChange={(event) => {
                                        const value = event.target.value === "true";

                                        setValue("eatSahiAllowed", value, {
                                            shouldValidate: true,
                                        });

                                        return value;
                                        }}
                                        row
                                    >
                                        <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label={t("status.enable")}
                                        />
                                        <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label={t("status.disable")}
                                        />
                                    </RadioGroup>
                                    )}
                                />
                                </FormControl>

                                <FormControl>
                                <FormLabel>{t("products.fields.calories")}</FormLabel>
                                <OutlinedInput
                                    id="calories"
                                    {...register("calories")}
                                    defaultValue={getValues("calories")}
                                    type="number"
                                    style={{
                                    width: "150px",
                                    height: "40px",
                                    }}
                                />
                                </FormControl>

                                <FormControl>
                                <FormLabel>{t("products.fields.proteine")}</FormLabel>
                                <OutlinedInput
                                    id="proteine"
                                    {...register("proteine")}
                                    defaultValue={getValues("proteine")}
                                    type="number"
                                    style={{
                                    width: "150px",
                                    height: "40px",
                                    }}
                                />
                                </FormControl>

                                <FormControl>
                                <FormLabel>{t("products.fields.fat")}</FormLabel>
                                <OutlinedInput
                                    id="fat"
                                    {...register("fat")}
                                    defaultValue={getValues("fat")}
                                    type="number"
                                    style={{
                                    width: "150px",
                                    height: "40px",
                                    }}
                                />
                                </FormControl>

                                <FormControl>
                                <FormLabel>{t("products.fields.carbs")}</FormLabel>
                                <OutlinedInput
                                    id="carbs"
                                    {...register("carbs")}
                                    defaultValue={getValues("carbs")}
                                    type="number"
                                    style={{
                                    width: "150px",
                                    height: "40px",
                                    }}
                                />
                                </FormControl>
                            </Stack>
                        </form>
                    </Box>
                </Stack>
            </Edit>
        </Drawer>
    );
};
